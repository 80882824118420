import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import Geocode from "react-geocode"

const EditCircleLocation = ({ addressLocationRef, setCircles, color }) => {
  useEffect(() => {
    addressLocationRef.current = {
      onMapClick,
    }
  }, [])

  const onMapClick = e => {
    setCircles([
      {
        center: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        radius: 10000,
        color: color ?? '#ffffff',
      },
    ])
  }

  return <React.Fragment></React.Fragment>
}

export default EditCircleLocation
