import { useFormik, yupToFormErrors } from "formik"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import * as Yup from "yup"

const CreateContractItemNoticeModal = ({
  alternative,
  isOpen,
  toggleModal,
  contractItemNoticeToEdit,
  reload,
  enterprise
}) => {
  const currentUser = userService.getLoggedInUser()
  const [contractOptions, setContractOptions] = useState([])
  const [contractStatuses, setContractStatuses] = useState([])

  useEffect(() => {
    if (
      isOpen &&
      contractItemNoticeToEdit != undefined &&
      contractItemNoticeToEdit != null
    ) {
      validation.resetForm()
      if (contractItemNoticeToEdit.contractItemID > -1) {
        validation.setFieldValue(
          "contractID",
          contractItemNoticeToEdit.contractID
        )
        validation.setFieldValue(
          "alternativeID",
          contractItemNoticeToEdit.alternativeID
        )
        validation.setFieldValue(
          "contractItemStatusID",
          contractItemNoticeToEdit.contractItemStatusID
        )
        validation.setFieldValue(
          "contractItemID",
          contractItemNoticeToEdit.contractItemID
        )
        validation.setFieldValue("vendor", contractItemNoticeToEdit.vendor)
        validation.setFieldValue("status", contractItemNoticeToEdit.status)
        validation.setFieldValue(
          "totalAmount",
          contractItemNoticeToEdit.totalAmount
        )
        validation.setFieldValue(
          "contractItemNum",
          contractItemNoticeToEdit.contractItemNum
        )
        validation.setFieldValue("poNumber", contractItemNoticeToEdit.poNumber)
        validation.setFieldValue(
          "approvalDate",
          contractItemNoticeToEdit.approvalDate
        )
        validation.setFieldValue(
          "startDate",
          contractItemNoticeToEdit.startDate
        )
        validation.setFieldValue("endDate", contractItemNoticeToEdit.endDate)
        validation.setFieldValue("comments", contractItemNoticeToEdit.comments)
      }

      loadData()
    }
  }, [isOpen, contractItemNoticeToEdit])

  // useEffect(() => {
  //   loadData()
  // }, [])

  const loadData = async () => {
    let cs = await api.getContractsForResource(alternative.scenarioID, 0)
    setContractOptions(cs)
    let s = await api.getList(currentUser.userID, "ContractItemStatus")
    setContractStatuses(s)
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      contractItemID: -1,
      contractID: -1,
      alternativeID: alternative.alternativeID,
      contractItemStatusID: -1,
      vendor: "",
      status: -1,
      totalAmount: 0,
      // contractItemNum: "",
      poNumber: "",
      approvalDate: null,
      startDate: null,
      endDate: null,
      comments: "",
    },
    validationSchema: Yup.object({
      contractItemNum: Yup.number().required("Enter a NOO #"),
    }),
    onSubmit: async values => {
      let newContract = { ...values }
      try {
        if (newContract.contractItemID > -1) {
          await api.updateContractItem(currentUser.userID, {
            ...contractItemNoticeToEdit,
            ...newContract,
          })
        } else {
          await api.createContractItem(currentUser.userID, newContract)
        }
        toast.success("Contract added successfully")
        reload()
        toggleModal()
      } catch (err) {
        toast.error("An error occured while saving the contract")
      }
    },
  })

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {enterprise && enterprise.obligationLabel}
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>NOO #</Label>
                <Input
                  type="number"
                  onChange={e =>
                    validation.setFieldValue("contractItemNum", e.target.value)
                  }
                  value={validation.values.contractItemNum}
                />
                {validation.touched.contractItemNum &&
                validation.errors.contractItemNum ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractItemNum}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>Amount</Label>
                <CurrencyInput
                  className="form-control align-end"
                  value={validation.values.totalAmount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("totalAmount", values.float)
                  }}
                  prefix={"$"}
                />
                {validation.touched.totalAmount &&
                validation.errors.totalAmount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.totalAmount}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>Approval Date</Label>
                <Input
                  name="startDate"
                  type="date"
                  onChange={e =>
                    validation.setFieldValue(
                      "approvalDate",
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(validation.values.approvalDate).format(
                    "YYYY-MM-DD"
                  )}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>PO #</Label>
                <Input
                  type="text"
                  onChange={e =>
                    validation.setFieldValue("poNumber", e.target.value)
                  }
                  value={validation.values.poNumber}
                />
                {validation.touched.poNumber && validation.errors.poNumber ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.poNumber}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>Start Date</Label>
                <Input
                  name="startDate"
                  type="date"
                  onChange={e =>
                    validation.setFieldValue(
                      "startDate",
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(validation.values.startDate).format(
                    "YYYY-MM-DD"
                  )}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>End Date</Label>
                <Input
                  name="endDate"
                  type="date"
                  onChange={e =>
                    validation.setFieldValue(
                      "endDate",
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(validation.values.endDate).format("YYYY-MM-DD")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>Status</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractItemStatusID}
                  onChange={e => {
                    validation.setFieldValue(
                      "contractItemStatusID",
                      e.target.value
                    )
                  }}
                >
                  <option value={-1}>Select ...</option>
                  {contractStatuses.map((c, idx) => (
                    <option key={idx} value={c.listItemID}>
                      {c.listItemName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col md="6">
              <div className="mb-3">
                <Label>Vendor</Label>
                <Input
                  type="text"
                  onChange={e =>
                    validation.setFieldValue("vendor", e.target.value)
                  }
                  value={validation.values.vendor}
                />
                {validation.touched.vendor && validation.errors.vendor ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.vendor}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>Appropriation</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractID}
                  onChange={e => {
                    validation.setFieldValue("contractID", e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {contractOptions.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.contractID}`}>
                        {a.contractNumber} - {a.contractName}
                      </option>
                    )
                  })}
                </select>
                {validation.touched.contractID &&
                validation.errors.contractID ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractID}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label>Notes</Label>
                <Input
                  name="paymentnotes"
                  type="textarea"
                  rows={3}
                  onChange={e =>
                    validation.setFieldValue("comments", e.target.value)
                  }
                  value={validation.values.comments}
                />
              </div>
            </Col>
          </Row>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary save-user">
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateContractItemNoticeModal
