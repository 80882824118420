import { RenderWidget } from "components/Widgets"
import NameAvatar from "components/custom/nameAvatar"
import React from "react"
import { Col, Row } from "reactstrap"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ModelsCards = ({ models, props }) => {
  const currentUser = userService.getLoggedInUser()

  const handleModelPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/projects/${model.scenarioID}`)
  }

  const handleAltPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/projects/${model.scenarioID}`)
  }

  const handleParticipantsPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/participants/${model.scenarioID}`)
  }

  const renderCard = (model, idx) => {
    return (
      <Col
        key={idx}
        /* md={4} sm={6} */
      >
        <div
          style={{
            border: "1px solid lightgray",
            backgroundColor: model.lockBit ? '#FCC4C9' : "white",
            marginTop: "20px",
            marginRight: "10px",
            marginLeft: "10px",
            borderRadius: "8px",
            minWidth: "400px",
            maxWidth: "500px",
            //boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1)'
          }}
        >
          <div
            style={{
              padding: "10px",
              backgroundColor: model.scenarioTypeBackColor,
              color: model.scenarioTypeForeColor,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <i className={model.scenarioTypeIcon}></i>
            <b> {model.scenarioName}</b>
          </div>
          <div style={{ padding: "5px", fontWeight: 600 }}>
            <NameAvatar
              fullName={model.portfolioManager}
              showName={true}
              base64Image={model.portfolioManagerPhotoBase64}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "46%",
                margin: "auto",
                marginTop: "5px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={e => handleParticipantsPress(e, model)}
            >
              {RenderWidget({ ...model.teamWidget, height: "100%" }, 1)}
            </div>
            <div
              style={{
                width: "46%",
                margin: "auto",
                marginTop: "5px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={e => handleAltPress(e, model)}
            >
              {RenderWidget({ ...model.businessWidget, height: "100%" }, 1)}
            </div>
          </div>
          <div
            className="widgFooter"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignContent: 'center',
              marginBottom: "5px",
            }}
          >
            <i
              className="mdi mdi-monitor-dashboard"
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={e =>
                props.history.push(`/dashboard/22/${model.scenarioID}`)
              }
            ></i>
            <i
              className="mdi mdi-sitemap"
              style={{ fontSize: "20px", cursor: "pointer" }}
              onClick={e =>
                props.history.push(`/PwcHierarchy/${model.scenarioID}`)
              }
            ></i>
            {currentUser.roleID >= 10 && (
              <i
                className="mdi mdi-cog-outline"
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={e => handleModelPress(e, model)}
              ></i>
            )}
            {model.lockBit == true && (
              <i
                className="fas fa-lock"
                style={{ fontSize: "20px", color: "red", paddingTop: '3px' }}
              ></i>
            )}
            {model.inactive == true && (
              <span style={{ fontSize: "20px", color: "red" }}>Inactive</span>
            )}

            {model.backupBit == true && (
              <span style={{ fontSize: "20px", color: "red" }}>Backup</span>
            )}
          </div>
          {model.rollupScenarios != undefined && (
            <div style={{ padding: "0px 15px 5px 15px" }}>
              {model.rollupScenarios.map((s, idx) => (
                <div key={idx}>
                  <a href="#" onClick={e => handleModelPress(e, s)}>
                    {s.scenarioName}
                  </a>
                  <br></br>
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>
    )
  }

  return (
    <React.Fragment>
      <Row>{models.map((m, idx) => renderCard(m, idx))}</Row>
    </React.Fragment>
  )
}

export default ModelsCards
