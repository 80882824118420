import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import CustomSelect from "components/Form/CustomSelect"
import useFetcher from "hooks/useFetcher"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ApplyResultsModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  optObjFin,
  scenario,
  reload
}) => {
  const currentUser = userService.getLoggedInUser()
  const [decisions] = useFetcher(
    () => api.getList(currentUser.userID, "Decisions"),
    []
  )
  const [plans] = useFetcher(() => api.getPlans(scenarioId), [])

  const [data, setData] = useState({
    decisionId: 20,
    otherDecisionId: 0,
    planId: 0,
  })

  useEffect(() => {
    if (isOpen) {
      setData({
        decisionId: 20,
        otherDecisionId: 0,
        planId: 0,
      })
    }
  }, [isOpen])

  const save = async () => {
    await api.applyOptimizationResults(
      optObjFin != null && optObjFin.opt != null
        ? optObjFin.opt.optModelID
        : scenario.currentOptModelID,
      data.decisionId,
      data.otherDecisionId,
      data.planId,
      currentUser.userID
    )
    toast.success("Optimization results applied")
    reload && reload()
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Apply Results to...
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3 d-flex-row ai-center gap-20">
          <Label className="form-label">Mark selected items as</Label>
          <CustomSelect
            label="Decision"
            value={data.decisionId}
            onChange={v => setData({ ...data, decisionId: v })}
            options={decisions}
          />
        </div>
        <div className="divider" />
        <div className="mb-3">
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="anon1"
              id="anon1"
              checked={data.otherDecisionId == 0}
              onClick={e => {
                setData({ ...data, otherDecisionId: 0 })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="anon1"
              style={{ marginLeft: "10px" }}
            >
              Mark others as Pending
            </label>
          </div>
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="anon2"
              id="anon2"
              checked={data.otherDecisionId == -1}
              onClick={e => {
                setData({ ...data, otherDecisionId: -1 })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="anon2"
              style={{ marginLeft: "10px" }}
            >
              Leave others as they are
            </label>
          </div>
        </div>
        <div className="divider" />
        <div className="mb-3">
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="anon3"
              id="anon3"
              checked={data.planId == 0}
              onClick={e => {
                setData({ ...data, planId: 0 })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="anon3"
              style={{ marginLeft: "10px" }}
            >
              Apply selected to the Decision screen
            </label>
          </div>
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="anon4"
              id="anon4"
              checked={data.planId != 0}
              onClick={e => {
                setData({ ...data, planId: -1 })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="anon4"
              style={{ marginLeft: "10px" }}
            >
              Apply selected to a plan
            </label>
          </div>
        </div>
        {data.planId != 0 && (
          <CustomSelect
            label="Plan"
            value={data.planId}
            onChange={v => setData({ ...data, planId: v })}
            options={plans.map(p => {
              return {
                listItemID: p.planID,
                listItemName: p.planName,
              }
            })}
          />
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ApplyResultsModal
