import React, { useState } from "react"
import { currencyFormatter } from "utils/formatters"

// const column = {
//   title,
//   field,
//   formatter?,
//   tdStyle
//   thStyle
//   sort
// }
const formatters = {
  stringAsHtml: (row, prop) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: row[prop],
        }}
      ></div>
    )
  },
  currency: (row, prop) => {
    return currencyFormatter.format(row[prop])
  },
}
const CustomTable = ({
  columns,
  items,
  setItems,
  selectable,
  pageSize,
  hasPagination,
}) => {
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setItems(direction == "asc" ? sortAsc(items, prop) : sortDesc(items, prop))
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      const aValue = a[prop];
      const bValue = b[prop];
  
      if (!isNaN(parseInt(aValue)) && !isNaN(parseInt(bValue))) {
        return parseInt(aValue) - parseInt(bValue); // Numeric comparison for numbers
      }
  
      // Fallback to string comparison for other types
      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    }
  
    return [...data].sort(compare);
  }

  const sortDesc = (data, prop) => {
    function compare(a, b) {
      const aValue = a[prop];
      const bValue = b[prop];
  
      if (!isNaN(parseInt(aValue)) && !isNaN(parseInt(bValue))) {
        return parseInt(bValue) - parseInt(aValue); // Numeric comparison for numbers
      }

      // Fallback to string comparison for other types
      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    }
  
    return [...data].sort(compare);
  }

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const getFormatted = (col, item) => {
    if (col.formatter !== undefined) {
      if (typeof col.formatter == "string") {
        let formatter = formatters[col.formatter]
        if (formatter !== undefined) {
          return formatter(item, col.field)
        }
      } else {
        return col.formatter(item)
      }
    }

    return item[col.field]
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSettings, setCurrentPageSettings] = useState(pageSize || 20)
  const pageSettings = [10, 20, 30, 50, 100]

  const goPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const goNext = () => {
    if (currentPage < Math.ceil(items.length / currentPageSettings)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const idxStart = (currentPage - 1) * currentPageSettings
  const idxEnd =
    currentPageSettings * currentPage > items.length
      ? items.length
      : currentPageSettings * currentPage

  return (
    <React.Fragment>
      <table
        className="table table-bordered low-padding-table table-condensed table-striped table-highlight"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            {columns.map((c, idx) => {
              if (c.sort) {
                return (
                  <th
                    key={idx}
                    onClick={() => handleSort(c.field)}
                    style={{
                      cursor: "pointer",
                      ...c.thStyle,
                    }}
                  >
                    {c.title} {getSortIcon(c.field)}
                  </th>
                )
              } else {
                return (
                  <th
                    key={idx}
                    style={{
                      ...c.thStyle,
                    }}
                  >
                    {c.title}
                  </th>
                )
              }
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => (
            <tr key={idx}>
              {columns.map((c, idxc) => (
                <td key={idxc} style={c.tdStyle}>
                  {getFormatted(c, item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {items.length > currentPageSettings && hasPagination && (
        <div className="tabulator" style={{ minHeight: "100px" }}>
          <div className="tabulator-footer">
            <span className="tabulator-paginator">
              <label>Page Size</label>
              <select
                className="tabulator-page-size"
                aria-label="Page Size"
                title="Page Size"
                value={currentPageSettings}
                onChange={e => setCurrentPageSettings(e.target.value)}
              >
                {pageSettings.map((p, idx) => (
                  <option key={idx} value={p}>
                    {p}
                  </option>
                ))}
              </select>
              <button
                className="tabulator-page"
                type="button"
                role="button"
                aria-label="Prev Page"
                title="Prev Page"
                data-page="prev"
                onClick={goPrev}
                disabled={currentPage == 1}
              >
                Prev
              </button>
              <span className="tabulator-pages">
                {Array.from(
                  Array(Math.ceil(items.length / currentPageSettings)).keys()
                ).map((i, idx) => (
                  <button
                    key={idx}
                    className={`tabulator-page ${
                      currentPage == i + 1 ? "active" : ""
                    }`}
                    type="button"
                    role="button"
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </span>
              <button
                className="tabulator-page"
                type="button"
                role="button"
                aria-label="Next Page"
                title="Next Page"
                data-page="next"
                onClick={goNext}
                disabled={
                  Math.ceil(items.length / currentPageSettings) ==
                  currentPage
                }
              >
                Next
              </button>
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default CustomTable
