import { swalWithConfirmButton } from "components/custom/swal"
import download from "downloadjs"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useSelector } from "react-redux"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const RunReportModal = ({ isOpen, toggleModal, reportData }) => {
  const currentUser = userService.getLoggedInUser()
  const [report, setReport] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { alternatives } = useSelector(state => ({
    alternatives: state.gridSelection.alternatives,
  }))
  const [years, setYears] = useState([])
  const [selectedYear, setSelectedYear] = useState(-1)
  const [show, setShow] = useState(1)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    if (isOpen && reportData) {
      setSelectedYear(-1)
      setShow(1)
      setIsLoading(false)
      loadData()
    }
  }, [isOpen, reportData])

  const loadData = async () => {
    let r = await api.getReport(reportData.reportId)
    setReport(r)
    if (reportData.scenarioId !== undefined) {
      let y = await api.getScenarioList(reportData.scenarioId, "ScenarioYears")
      setYears(y)
    }
  }

  const runReport = async outputType => {
    setIsLoading(true)
    let sd = report.showDates ? startDate : null
    let ed = report.showDates ? endDate : null
    let reportPathSplit = report.reportPath.split("/")
    let scenarioId = window.location.pathname
      .split("/")
      .filter(x => x !== null && x !== "")[1]
    const scenario = await api.getScenario(scenarioId)
    const scenarioName =
      scenario !== undefined && scenario !== null && report.inScenario
        ? `${scenario.scenarioName}-`
        : ""

    if (report.showSelectedAlternativeOption && show == 2) {
      api
        .runReport({
          outputType: outputType,
          scenarioID: scenarioId,
          reportPath: report.reportPath,
          alternativeList: "ALL",
          startDate: sd,
          endDate: ed,
          fileName: reportPathSplit[reportPathSplit.length - 1],
          yearID: selectedYear,
        })
        .then(blob => {
          download(
            blob,
            scenarioName +
              reportPathSplit[reportPathSplit.length - 1].split(".")[0] +
              "." +
              (outputType == "pdf" ? "pdf" : "xls")
          )
          setIsLoading(false)

          toggleModal()
        })
    } else {
      if (report.showSelectedAlternativeOption && alternatives.length == 0) {
        setIsLoading(false)
        swalWithConfirmButton.fire({
          title: `You cannot run this option without Projects selected`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      } else {
        api
          .runReport({
            outputType: outputType,
            scenarioID: scenarioId,
            reportPath: report.reportPath,
            alternativeList: alternatives
              .map(
                x =>
                  x[
                    Object.keys(x).find(
                      key => key.toLowerCase() === "alternativeid"
                    )
                  ]
              )
              .join(","),
            startDate: sd,
            endDate: ed,
            fileName: reportPathSplit[reportPathSplit.length - 1],
            yearID: selectedYear,
          })
          .then(blob => {
            download(
              blob,
              scenarioName +
                reportPathSplit[reportPathSplit.length - 1].split(".")[0] +
                "." +
                (outputType == "pdf" ? "pdf" : "xls")
            )
            setIsLoading(false)

            toggleModal()
          })
      }
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Generate Report
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            {report !== null && (
              <>
                <div>
                  <Label className="form-label">{report.reportName}</Label>
                  <p>{report.description}</p>
                </div>

                {report.showStartYear && (
                  <div className="mb-3">
                    <Label className="form-label">Start Year</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedYear}
                      onChange={e => {
                        setSelectedYear(e.target.value)
                      }}
                    >
                      <option value="-1">Select Start Year</option>
                      {years.map((x, idx) => (
                        <option key={idx} value={x.listItemName}>
                          {x.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {report.showDates && (
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>Start Date</Label>
                        <Input
                          name="startDate"
                          type="date"
                          onChange={e =>
                            setStartDate(moment(e.target.value, "YYYY-MM-DD"))
                          }
                          value={moment(startDate).format("YYYY-MM-DD")}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label>End Date</Label>
                        <Input
                          name="endDate"
                          type="date"
                          onChange={e =>
                            setEndDate(moment(e.target.value, "YYYY-MM-DD"))
                          }
                          value={moment(endDate).format("YYYY-MM-DD")}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {report.showSelectedAlternativeOption && (
                  <div className="mb-3">
                    <Label>Discounting</Label>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="anon4"
                        id="anon4"
                        checked={show == 1}
                        onClick={e => {
                          setShow(1)
                        }}
                        disabled={
                          window.location.pathname
                            .toLowerCase()
                            .indexOf("enterpriseprojects") > -1
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="anon4"
                        style={{ marginLeft: "10px" }}
                      >
                        Include selected projects only
                      </label>
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="anon5"
                        id="anon5"
                        checked={show == 2}
                        onClick={e => {
                          setShow(2)
                        }}
                        disabled={
                          window.location.pathname
                            .toLowerCase()
                            .indexOf("enterpriseprojects") > -1
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="anon5"
                        style={{ marginLeft: "10px" }}
                      >
                        Include all projects
                      </label>
                    </div>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => {
            toggleModal()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div>
          <button className="btn btn-primary" onClick={() => runReport("pdf")}>
            Generate PDF
          </button>
          <button
            className="btn btn-primary save-user"
            onClick={() => runReport("excel")}
          >
            Generate XLS
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RunReportModal
