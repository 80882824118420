import Widgets from "components/Widgets"
import { mapObjPropsToLowercase } from "helpers/objPropsToLowercaseMapper"
import displayDropdownList from "pages/_AlternativeShared/DisplayDropdownList"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Col,
  Container,
  Label,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
  Input,
} from "reactstrap"
import api from "services/api.service"
import { ObjFinAlert } from "services/scenario.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AlternativeRatingsGrid from "./AlternativeRatingsGrid"
import { exportTableToExcel } from "utils/exportToExcel"
import { extractInnerTextFromHtml } from "utils/formatters"

const Ratings = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  let [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  let [objectives, setObjectives] = useState([])
  let [objectivesAndMeasures, setObjectivesAndMeasures] = useState([])
  let [objectiveDropdownSelection, setObjectiveDropdownSelection] =
    useState(null)
  let [displayDropdownSelection, setDisplayDropdownSelection] = useState({
    text: "Group Ratings",
    value: "Verbal",
  })
  let [isLoading, setIsLoading] = useState(false)
  let [gridData, setGridData] = useState([])
  let [scenario, setScenario] = useState(null)
  let [widgets, setWidgets] = useState([])

  const [selectedScenarioId, setSelectedScenarioId] = useState(-1)
  const [scenarioChildTypes, setScenarioChildTypes] = useState([])
  const [showExcluded, setShowExcluded] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "ratings",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "ratings",
      })
    )
    setSelectedScenarioId(scenarioId)
    loadScenario(scenarioId)
  }, [scenarioId])

  useEffect(() => {
    if (selectedScenarioId > -1) loadData()
  }, [selectedScenarioId])

  useEffect(() => {
    if (
      objectiveDropdownSelection != null &&
      displayDropdownSelection != null &&
      selectedScenarioId > -1
    ) {
      loadGrid()
    }
  }, [objectiveDropdownSelection, displayDropdownSelection, selectedScenarioId, showExcluded])

  const loadScenario = async scenarioId => {
    let scen = await api.getScenario(scenarioId)
    setScenario(scen)
  }

  const loadData = async () => {
    try {
      setIsLoading(true)

      let objs = await api.getObjectives(selectedScenarioId)
      setObjectives(objs)
      let w = await api.getWidgetsByView("ratings", selectedScenarioId)
      setWidgets(w)
      let sct = await api.getList(currentUser.userID, "ScenarioChildType")
      setScenarioChildTypes(sct)

      let objsAndMeasures = []
      objs.forEach(o => {
        objsAndMeasures.push(o)
        if (o.measures && o.measures.length > 0) {
          o.measures.forEach(m => {
            objsAndMeasures.push(m)
          })
        }
      })
      setObjectivesAndMeasures(objsAndMeasures)
      let scen = await api.getScenario(selectedScenarioId)
      setObjectiveDropdownSelection({
        value: 0,
        text: scen.scenarioName,
      })
      setObjectivesDropdownList([
        {
          value: 0,
          text: scen.scenarioName,
        },
        {
          value: -99,
          text: scen.scenarioName + " with Measures",
        },
        ...objs
          .filter(x => x.measures && x.measures.length > 0)
          .map(x => {
            return {
              value: x.objectiveID,
              text: x.objectiveName,
            }
          }),
      ])

      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadGrid = async () => {
    setIsLoading(true)
    let data = await api.getAlternativeRatings(
      objectiveDropdownSelection.value,
      selectedScenarioId,
      displayDropdownSelection.value,
      optObjFin != null && optObjFin.obj != null ? optObjFin.obj.objModelID : 0,
      currentUser.userID,
      showExcluded
    )
    setGridData(mapObjPropsToLowercase(data))
    setIsLoading(false)
  }

  const exportToExcel = () => {
    let props = []
    props.push({ property: "alternativeNum", heading: "ID" })
    props.push({ property: "alternative", heading: "Project" })

    if (objectiveDropdownSelection.value == 0) {
      objectives.forEach(o => {
        props.push({
          property: o.objectiveName
            .toLowerCase()
            .replaceAll(",", "")
            .replaceAll('"', "")
            .replaceAll("'", ""),
          heading: o.objectiveName,
        })
      })
    } else if (objectiveDropdownSelection.value == -99) {
      objectivesAndMeasures.forEach(o => {
        props.push({
          property: o.measureName
            .toLowerCase()
            .replaceAll(",", "")
            .replaceAll('"', "")
            .replaceAll("'", ""),
          heading: o.measureName,
        })
      })
    } else if (objectiveDropdownSelection.value > 0) {
      objectivesAndMeasures
        .filter(
          x =>
            x.measureID != undefined &&
            x.objectiveID == objectiveDropdownSelection.value
        )
        .forEach(o => {
          props.push({
            property: o.measureName
              .toLowerCase()
              .replaceAll(",", "")
              .replaceAll('"', "")
              .replaceAll("'", ""),
            heading: o.measureName,
          })
        })
    }

    props.push({ property: "saaScore", heading: "Benefit Score" })
    props.push({ property: "saaRating", heading: "Group Rating" })

    exportTableToExcel(
      JSON.parse(JSON.stringify([...gridData])).map(x => {
        props.forEach(p => {
          x[p.property] = extractInnerTextFromHtml(x[p.property])
        })
        return x
      }),
      props,
      "Ratings.csv"
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />

          <Widgets widgets={widgets} marginTop="20px" />

          {scenario != null && (
            <>
              <ButtonGroup>
                <button
                  className={`btn  ${
                    selectedScenarioId == scenario.scenarioID
                      ? " btn-primary"
                      : " btn-outline-secondary"
                  }`}
                  onClick={() => setSelectedScenarioId(scenario.scenarioID)}
                >
                  {scenario.scoreName}
                </button>
                {scenario?.secondaryScenarios?.map((s, idx) => (
                  <button
                    key={idx}
                    className={`btn ${
                      s.scenarioID == selectedScenarioId
                        ? " btn-primary"
                        : " btn-outline-secondary"
                    }`}
                    onClick={() => setSelectedScenarioId(s.scenarioID)}
                  >
                    {
                      scenarioChildTypes.find(
                        x => x.listItemID == s.scenarioChildTypeID
                      )?.listItemName
                    }
                  </button>
                ))}
              </ButtonGroup>
            </>
          )}

          {objectiveDropdownSelection != null &&
            objectivesDropdownList.length > 2 && (
              <Row>
                <div style={{ width: "40%", marginTop: "10px" }}>
                  <Label style={{ marginBottom: "0px" }}>View</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={objectiveDropdownSelection.value}
                    onChange={e => {
                      setObjectiveDropdownSelection(
                        objectivesDropdownList.find(
                          x => x.value == e.target.value
                        )
                      )
                    }}
                  >
                    {objectivesDropdownList.map((r, idx) => {
                      return (
                        <option key={idx} value={`${r.value}`}>
                          {r.text}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Row>
            )}
          <Row>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="d-flex-row gap-3">
                <div style={{ width: "200px" }}>
                  <Label style={{ marginBottom: "0px" }}>Show</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={displayDropdownSelection.value}
                    onChange={e => {
                      setDisplayDropdownSelection(
                        displayDropdownList.find(x => x.value == e.target.value)
                      )
                    }}
                  >
                    {displayDropdownList.map((r, idx) => {
                      return (
                        <option key={idx} value={`${r.value}`}>
                          {r.text}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="ff-excluded"
                    checked={showExcluded}
                    onClick={e => {
                      setShowExcluded(!showExcluded)
                    }}
                  />
                  <Label className="form-check-label" for="ff-excluded">
                    Show Excluded {scenario?.alternativeName}s
                  </Label>
                </div>
              </div>
              <Dropdown
                // style={{ display: "inline-block" }}
                isOpen={showActionsDropdown}
                toggle={() => setShowActionsDropdown(!showActionsDropdown)}
              >
                <DropdownToggle
                  tag="button"
                  className="btn btn-outline-primary save-user"
                >
                  Actions <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      exportToExcel()
                    }}
                  >
                    <i className="fas fa-file-excel"></i> Export to Excel
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {/*               <i
                className="fas fa-file-excel"
                style={{ cursor: "pointer" }}
                onClick={exportToExcel}
              ></i> */}
            </div>
          </Row>
          <ObjFinAlert optObjFin={optObjFin} />
          <Row style={{ marginTop: "20px" }}>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {gridData.length > 0 && (
                <AlternativeRatingsGrid
                  data={gridData}
                  setData={setGridData}
                  dropdownSelection={objectiveDropdownSelection}
                  objectives={objectives}
                  alternativeName={scenario.alternativeName}
                  objectivesAndMeasures={objectivesAndMeasures}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Ratings
