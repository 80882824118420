import { CHANGE_BUBBLESCATTERCHART_OPTIONS } from "./actionTypes"

const INIT_STATE = {
  bubbleScatterChartOptions: {
    minX: 0,
    maxX:0,
    minY:0,
    maxY:0,
    xAxisAuto:true,
    yAxisAuto: true,
    xAxisField: 'Cost',
    showNames: false,
    q1:'',
    q2:'',
    q3:'',
    q4:''
  },
}

const bubbleScatterChartOptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_BUBBLESCATTERCHART_OPTIONS:
      return {
        ...state,
        bubbleScatterChartOptions: action.payload,
      }

    default:
      return state
  }
}

export default bubbleScatterChartOptions
