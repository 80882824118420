import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const OccNeedAssesmentSection = ({
  activeTab,
  alternativeId,
  alternative,
  scenario,
  changeAlternativeProp,
  triggerSave,
  setTriggerSave,
  showToastChanges,
  handleSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [businessProcesses, setBusinessProcesses] = useState([])
  const [scItems, setScItems] = useState([])
  const [selectedScorecard, setSelectedScorecard] = useState("Comptroller")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (activeTab == "OCC_Assessment") {
      setScItems([])
      setSelectedScorecard("Comptroller")
      loadData()
    }
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async () => {
    setIsLoading(true)
    let bs = await api.getList(currentUser.userID, "CoreBusinessProcess")
    setBusinessProcesses(bs)
    await loadScItems()
    setIsLoading(false)
  }

  const loadScItems = async () => {
    setIsLoading(true)
    let sc = await api.getAlternativeScorecardItems(alternativeId)
    let data = []
    let item = null
    for (let i = 0; i < sc.length; i++) {
      if (sc[i].scorecardItemID == 0) {
        if (item != null) {
          data.push({ ...item })
        }
        item = { header: sc[i], rows: [] }
      } else {
        item.rows.push(sc[i])
      }
    }
    if (item != null) {
      data.push({ ...item })
    }

    if (alternative.scorecard == "Comptroller3" || alternative.scorecard == "Comptroller3a" || alternative.scorecard == "OMComptroller3" ) {
      let scenarioRatings = await api.getScenarioInputRatings(
        alternative.scenarioID
      )

      for (let item of data) {
        item.scenarioRatings = [
          { ratingID: -1, inputValue: "Not scored", ratingName: "Not scored" },
          ...scenarioRatings,
        ]

        let altScore = await api.getAltScoreByAltId(
          alternative.scenarioID,
          alternative.alternativeID,
          item.header.objectiveID,
          1
        )
        let altUserScore = await api.getAltUserScore(
          altScore.altScoreID,
          altScore.levelID,
          scenario.userID
        )
        item.altScore = altScore
        item.altUserScore = altUserScore[0]
        item.userScore = altUserScore[0].userScored
          ? altUserScore[0].userScore
          : "Not scored"
      }
    }

    setScItems(data)
    setIsLoading(false)
  }

  const changeScItemProp = (prop, value, scItem) => {
    let copy = [...scItems]
    let item = undefined
    for (let i = 0; i < copy.length; i++) {
      item = copy[i].rows.find(x => x.scorecardItemID == scItem.scorecardItemID)
      if (item != undefined) {
        break
      }
    }
    if (item != undefined) {
      item[prop] = value
      item.isDirty = true

      if(prop == 'comments'){
        if(value !== undefined && value !== null && value !== "" && value.length > 0){
          item.score = 1
        }else{
          item.score = 0
        }
      }

      showToastChanges()
    }
    changeAlternativeProp("isDirty", true)
    setScItems(copy)
  }

  const save = async () => {
    setTriggerSave(false)
    let itemsToUpdate = []
    scItems.forEach(item => {
      let update = item.rows.filter(x => x.isDirty)
      if (update.length > 0) {
        itemsToUpdate = [...itemsToUpdate, ...update]
      }
    })
    if (itemsToUpdate.length > 0) {
      let tasks = itemsToUpdate.map(x => async () => {
        return await api.addUpdateScorecardItem(
          currentUser.userID,
          alternativeId,
          x
        )
      })
      await Promise.all(tasks.map(t => t()))
    }
    await loadScItems()
  }

  const saveUserScore = async (e, idx) => {
    let copy = [...scItems]
    let item = copy[idx]
    item.userScore = e.target.value
    setScItems(copy)
    if (item.userScore == "Not scored") {
      await api.deleteAltUserScore(
        scenario.userID,
        item.altScore.altScoreID,
        item.altUserScore.levelID
      )
    } else {
      let score = {
        altScoreID: item.altScore.altScoreID,
        altUserScoreID: item.altUserScore.altUserScoreID,
        levelID: item.altUserScore.levelID,
        userScored: true,
        userID: scenario.userID,
        userScore: e.target.value,
      }
      item.altUserScore = { ...item.altUserScore, ...score }
      await api.updateAltUserScore(item.altScore.levelID, { ...score })
    }
  }

  return (
    <React.Fragment>
      {alternativeId > 0 && (
        <>
          <Row className="mb-3">
            <Col sm="12">
              <div className="mb-3">
                <Label className="form-label">Mission Assessment</Label>
                <br></br>
                <br></br>
                <Label className="form-label">
                  What core business activity is associated with this
                  investment?
                </Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={alternative.coreBusinessProcess || -1}
                  onChange={e => {
                    changeAlternativeProp("coreBusinessProcess", e.target.value)
                  }}
                >
                  {businessProcesses.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemName}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div
                className="mb-3"
                style={{ display: "flex", flexDirection: "row" }}
              >

{currentUser.roleID > 8  && (  
                <div style={{ width: "350px" }}>
                  <Label className="form-label">Scorecard</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={alternative.scorecard}
                    onChange={e => {
                      changeAlternativeProp("scorecard", e.target.value)
                    }}
                  >
                    <option value="Comptroller">Comptroller Scorecard 1</option>
                    <option value="Comptroller2">
                      Comptroller Scorecard 2
                    </option>
                    <option value="Comptroller3">
                      Comptroller Scorecard 3
                    </option>
                    <option value="OMComptroller3">
                      OM Comptroller Scorecard 3
                    </option>   
                    <option value="Comptroller3a">
                      Comptroller Scorecard 3a
                    </option>                                     
                  </select>
                </div>
              )}                    
              </div>
              {currentUser.roleID > 8  && (           
              <button className="btn btn-primary" onClick={handleSave}>
                Update Scorecard
              </button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading &&
                scItems.length > 0 &&
                scItems.map((sc, idx) => (
                  <div className="mb-3" key={idx}>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "4%" }}>
                          {sc.header.headerOrder}
                          </th>
                          <th style={{ width: "46%" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div style={{width: '72%'}}
                                dangerouslySetInnerHTML={{
                                  __html: sc.header.header,
                                }}
                              ></div>

                              {(alternative.scorecard == "Comptroller3" || alternative.scorecard == "OMComptroller3") || alternative.scorecard == "OMComptroller3a" &&
                                sc.scenarioRatings &&
                                sc.scenarioRatings.length > 0 && (
                                  <select
                                    className="form-control form-select select2 mb-xxl-0"
                                    style={{ width: "35%" }}
                                    value={sc.userScore}
                                    onChange={e => {
                                      saveUserScore(e, idx)
                                    }}
                                  >
                                    {sc.scenarioRatings &&
                                      sc.scenarioRatings.map((s, idx) => (
                                        <option key={idx} value={s.inputValue}>
                                          {s.ratingName}
                                        </option>
                                      ))}
                                  </select>
                                )}
                            </div>
                          </th>
                          <th style={{ width: "3%" }}>
                            <Input
                              type="checkbox"
                              readOnly={true}
                              checked={
                                sc.rows.find(x => x.score == 1) !== undefined
                              }
                            />
                          </th>
                          <th style={{ width: "35%" }}>
                            {sc.header.commentHeader}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sc.rows.map((r, idx) => (
                          <tr key={idx}>
                            <td>{r.qNumber}</td>
                            <td>
                            <div
                                dangerouslySetInnerHTML={{
                                  __html: r.itemText,
                                }}
                              ></div>
                              </td>
                            <td>
                              <Input
                                type="checkbox"
                                checked={r.score == 1}
                                readOnly={true}
                                // onClick={() => {
                                //   changeScItemProp(
                                //     "score",
                                //     r.score == 0 ? 1 : 0,
                                //     r
                                //   )
                                // }}
                              />
                            </td>
                            <td>
                              <TextareaAutosize
                                minRows={1}
                                className="form-control"
                                onChange={e => {
                                  changeScItemProp(
                                    "comments",
                                    e.target.value,
                                    r
                                  )
                                }}
                                value={r.comments}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col sm="12"></Col>
      </Row>
    </React.Fragment>
  )
}

export default OccNeedAssesmentSection
