import { swalWithConfirmButton } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { authenticationService } from "services/authentication.service"
import colors from "utils/colors"
import { getMode } from "."
import logo from "../../../assets/images/defprologo1.png"
import isNullOrEmpty from "utils/isNullOrEmpty"

const MobileLoginCode = props => {
  const pwd = "rAndOmpA$$Mw."
  const [email, setEmail] = useState("")
  const [userCode, setUserCode] = useState("")
  const [code, setCode] = useState("")
  const [invalidCode, setInvalidCode] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [scenario, setScenario] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [participant, setParticipant] = useState(null)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  const [phase, setPhase] = useState("1")

  const [errorText, setErrorText] = useState("")

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    setIsLoading(false)
  }

  const findScenario = async () => {
    setInvalidCode(false)
    if (code == null || code == "") {
      swalWithConfirmButton.fire({
        title: `Please enter your session code.`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      let tokenResp = await api.verifyCode(code)
      if (!isNullOrEmpty(tokenResp?.access_token)) {
        localStorage.setItem("currentData", JSON.stringify(tokenResp))

        let scen = await api.getScenario(code.substring(1, code.length - 1))
        if (scen == null) {
          setInvalidCode(true)
        } else {
          setScenario(scen)
          setPhase("2")
        }
      } else {
        setInvalidCode(true)
      }
    }
  }

  const findEmail = async () => {
    setInvalidEmail(false)
    if (validateEmail(email)) {
      let u = await api.getParticipantByEmail(scenario.scenarioID, email)
      if (u == null || (u.user == null && u.participantID == 0)) {
        setPhase("4")
      } else {
        setParticipant(participant)
        setPhase("3")
        if (
          u.modelRoleID !== 2 &&
          u.modelRoleID !== 11 &&
          u.modelRoleID !== 5
        ) {
          authenticationService.login(email, u.user.password).then(
            async user => {
              await checkModeAndRedirect(u)
            },
            error => {}
          )
        }
      }
    } else {
      setInvalidEmail(true)
    }
  }

  const checkModeAndRedirect = async part => {
    let mode = getMode(scenario, part, code[code.length - 1])
    if (mode.showStart) {
      if (scenario.pairwiseStatusID == 1) {
        props.history.push(
          `/mobile/objective/${scenario.scenarioID}/${code[code.length - 1]}`
        )
      } else {
        props.history.push(
          `/mobile/alternative/${scenario.scenarioID}/${code[code.length - 1]}`
        )
      }
    } else {
      setPhase("5")
      setErrorText(mode.text)
    }
  }

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const createParticipant = async () => {
    let id = await api.createParticipant(0, {
      modelRoleID: 1,
      pwcTypeID: code[code.length - 1],
      altTypeID: code[code.length - 1],
      user: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: pwd,
      },
      scenarioID: scenario.scenarioID,
    })
    if (id > 0) {
      authenticationService.login(email, pwd).then(
        async user => {
          let p = await api.getParticipant(id)
          await checkModeAndRedirect(scenario, p)
        },
        error => {}
      )
    }
  }

  const retry = () => {
    setPhase("1")
    setErrorText("")
    setLastName("")
    setFirstName("")
    setParticipant(null)
    setScenario(null)
    setInvalidEmail(false)
    setInvalidCode(false)
    setCode("")
    setUserCode("")
    setEmail("")
  }

  return (
    <React.Fragment>
      <div style={{ overflow: "hidden", color: "white" }}>
        <Row
          className="background-gradient"
          style={{ height: "100vh", display: "flex", justifyContent: "center" }}
        >
          <Col
            md="6"
            sm="12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
            }}
          >
            <img src={logo} alt="" style={{ width: "70%", height: "auto" }} />
            {/* {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )} */}
            <div>
              <div className="mb-4">
              <p className="mb-0"><b>Supercharge your project portfolio</b></p>
                <p>Collaborate. Prioritize. Optimize.</p>
              </div>
              <div className="mb-4">
                <h3>
                  <b>Welcome to Definitive Pro.</b>
                </h3>
              </div>
            </div>

            {phase == "1" && (
              <div>
                <p>Enter the session code and click Submit</p>
                <div className=" mb-4">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Session Code..."
                    value={code}
                    onChange={e => setCode(e.target.value)}
                  />
                  {invalidCode && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Invalid Code
                    </div>
                  )}
                </div>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={findScenario}
                >
                  Submit
                </button>
              </div>
            )}
            {scenario != null && phase == "2" && scenario.anonymous && (
              <div>
                <p>Enter your user code and click Submit</p>
                <div className=" mb-4">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="User Code..."
                    value={userCode}
                    onChange={e => setUserCode(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={findScenario}
                >
                  Submit
                </button>
              </div>
            )}
            {scenario != null && phase == "2" && !scenario.anonymous && (
              <div>
                <p>Enter your email and click Submit</p>
                <div className=" mb-4">
                  <Input
                    type="email"
                    className="form-control"
                    placeholder="Email..."
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  {invalidEmail && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Email is not valid
                    </div>
                  )}
                </div>

                <button className="btn btn-primary btn-lg" onClick={findEmail}>
                  Submit
                </button>
              </div>
            )}
            {phase == "3" &&
              participant != null &&
              (participant.modelRoleID == 2 ||
                participant.modelRoleID == 11 ||
                participant.modelRoleID == 5) && (
                <div>
                  <p>
                    You are part of this model but don’t have rights to score –
                    please contact the decision manager
                  </p>
                </div>
              )}
            {phase == "4" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p>
                  This appears to be your first time participating in a
                  Definitive Pro session. Please enter your first and last name.
                </p>
                <Label>First name</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="First name..."
                  style={{ width: "200px" }}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
                <Label>Last name</Label>
                <Input
                  type="text"
                  className="form-control mb-4"
                  style={{ width: "200px" }}
                  placeholder="Last name..."
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
                <button
                  className="btn btn-primary btn-lg"
                  onClick={createParticipant}
                >
                  Submit
                </button>
              </div>
            )}
            {phase == "5" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p>{errorText}</p>
                <button className="btn btn-primary btn-lg" onClick={retry}>
                  Try Another
                </button>
              </div>
            )}
            <div>
              <p className="mb-0">
                U.S. Patent Nos. 10,268,977; 10,366,361; 10,417,590
              </p>
              <p>All Rights Reserved</p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default MobileLoginCode
