import React, { useState, useEffect, useRef } from "react"
import EditTaskModal, { TaskTypes } from "./Modals/EditTaskModal"
import { userService } from "services/user.service"
import useModal from "hooks/useModalHook"
import colors from "utils/colors"
import Loader from "react-loaders"
import { ButtonGroup, Col, Label, Row, Input } from "reactstrap"
import UploadModal from "components/GlobalModals.js/UploadModal"
import api from "services/api.service"
import SchedulerTaskTable from "components/custom/Syncfusion/SchedulerTaskTable"
import moment from "moment"
import GanttTaskTable from "./Drawer/GanttTaskTable"
import KanbanTaskTable from "pages/Projects/EditProject/Drawer/KanbanTaskTable"
import { loadUsersAvatars } from "utils/avatar"
import {
  AlternativeConfigField,
  HealthAndStatusRadioButtons,
} from "./GovernanceSection"
import CurrencyInput from "react-currency-input-field"
import { TextareaAutosize } from "@material-ui/core"
import { currencyFormatter } from "utils/formatters"
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor"
import isNullOrEmpty from "utils/isNullOrEmpty"
import useFetcher from "hooks/useFetcher"
import GanttTaskTableNew from "./Drawer/GanttTaskTableNew"

const ProjectPlanSection = ({
  alternative,
  alternativeId,
  revisionAlternativeId,
  scenarioId,
  activeTab,
  changeAlternativeProp,
  fieldConfigItems,
  hasProjectPlanError,
  scenario,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [flatTaskList, setFlatTaskList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [taskToEdit, setTaskToEdit] = useState(null)
  const [taskType, setTaskType] = useState(null)
  const [widgets, setWidgets] = useState([])
  const ganttRef = useRef()

  const { show: showEditModal, toggle: toggleEditModal } = useModal()
  const { show: showUploadTasksModal, toggle: toggleUploadTasksModal } =
    useModal()
  // 0 - Gantt, 1 - Scheduler, 2 - Kanban, 3 - Summary
  const [displayAs, setDisplayAs] = useState(3)

  const [holidays, setHolidays] = useState([])

  const newTask = {
    taskID: -1,
    scenarioID: scenarioId,
    taskTypeID: TaskTypes.projectTask,
    taskName: "",
    requestTypeID: -1,
    taskStatusID: -1,
    taskDescription: "",
    taskDisposition: "",
    taskRisk: "",
    calcResult: "TBD",
    taskNum: 0,//"TBD",
    createDT: moment(new Date()).format("MM/DD/YYYY"),
    startDT: moment(new Date()).format("MM/DD/YYYY"),
    endDT: moment(new Date()).format("MM/DD/YYYY"),
    durationDays: 1,
    createUser: currentUser.firstName + " " + currentUser.lastName,
    dueDT: null,
    completeDT: null,
    alternativeID: alternative
      ? revisionAlternativeId > 0
        ? revisionAlternativeId
        : alternativeId
      : 0,
    predTaskIDs: [],
    parentTaskID: 0,
    subtasks: [],
  }

  useEffect(() => {
    loadHolidays()
  }, [])

  useEffect(() => {
    if (activeTab == "ProjectPlan") {
      setDisplayAs(3)
      loadTasks()
      loadTaskType()
    }
  }, [activeTab, alternativeId])

  const loadTaskType = async () => {
    let taskType = await api.getTaskType(
      TaskTypes.projectTask,
      currentUser.enterpriseID,
      scenarioId
    )
    setTaskType(taskType)
  }

  const loadTasks = async () => {
    setIsLoading(true)

    let allTasks = await api.getGeneralTasks(
      TaskTypes.projectTask,
      alternative ? "Alternatives" : "Scenario",
      alternative
        ? revisionAlternativeId > 0
          ? revisionAlternativeId
          : alternativeId
        : scenarioId,
      currentUser.userID
    )
    let statuses = await api.getTaskList(TaskTypes.projectTask, "TaskStatus")

    let tsksRewrite = allTasks.map(tsks => {
      let sts = statuses.find(x => x.listItemID == tsks.taskStatusID)
      if (sts == undefined) {
        tsks.taskStatusText = "Other / Unknown"
      } else {
        tsks.taskStatusText = sts.listItemName
      }

      tsks.type = "task"
      tsks.subtasks = []
      tsks.Predecessor = tsks.predTaskIDs
        ? tsks.predTaskIDs.map(x => x.trim()).join(",")
        : null

      // tsks.durationDays = moment(tsks.endDT, "YYYY-MM-DD").diff(
      //   moment(tsks.startDT, "YYYY-MM-DD"),
      //   "days"
      // )

      tsks.startDT = new Date(tsks.startDT)

      tsks.endDT = new Date(tsks.endDT)

      tsks.ownerUserObj = {
        value: tsks.ownerUserID,
        text: tsks.ownerUser,
      }

      return tsks
    })

    tsksRewrite = await loadAvatars(tsksRewrite)

    console.log("LOADED")
    console.log(tsksRewrite)

    setFlatTaskList(tsksRewrite)

    setIsLoading(false)
  }

  const loadHolidays = async () => {
    let h = await api.getHolidays(currentUser.enterpriseID)
    const weekends = generateWeekends(
      moment().subtract("years", 2),
      moment().add("years", 2)
    )

    let list = [
      ...h.map(x => {
        return { date: moment(x.date).format("YYYY-MM-DDTHH:mm:ss") }
      }),
      ...weekends.map(x => {
        return { date: moment(x.date).format("YYYY-MM-DDTHH:mm:ss") }
      }),
    ].filter(x => !isNullOrEmpty(x.date))

    setHolidays(list)
  }

  const generateWeekends = (startDate, endDate) => {
    const weekends = []
    const currentDate = moment(startDate)

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const dayOfWeek = currentDate.day()
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // 0: Sunday, 6: Saturday
        weekends.push({
          date: currentDate.format("YYYY-MM-DDTHH:mm:ss"),
          label: "Weekend",
        })
      }
      currentDate.add(1, "days")
    }
    return weekends
  }

  const loadAvatars = async tsks => {
    let userIds = tsks.map(x => x.ownerUserID)

    if (userIds.length > 0) {
      let avatars = await loadUsersAvatars(userIds)
      tsks.forEach(t => {
        let ownerUserPhoto = avatars.find(x => x.userID == t.ownerUserID)
        t.ownerUserPhotoBase64 =
          ownerUserPhoto == undefined ? "" : ownerUserPhoto.photoBase64
      })
    }

    return tsks
  }

  const addTask = () => {
    let maxTaskNum =
      flatTaskList == null || flatTaskList.length == 0
        ? 0
        : Math.max(...flatTaskList.map(o => parseInt(o.taskNum)))
    let nextIndex =
      flatTaskList == null || flatTaskList.length == 0
        ? 0
        : Math.max(...flatTaskList.map(x => x.index)) + 1
    setTaskToEdit({
      ...newTask,
      taskTypeID: TaskTypes.projectTask,
      taskNum: maxTaskNum + 1,
      index: nextIndex,
      subtasks: [],
    })
    toggleEditModal()
  }

  const editTask = async (e, task) => {
    e && e.preventDefault()
    //TODO check why NULL
    let tsk = await api.getTask(task.taskID)
    // var dataItem = flatTaskList.find(x => x.taskNum === rowData.taskNum)
    setTaskToEdit({
      ...tsk,
      subtasks: flatTaskList.filter(x => x.parentTaskID == tsk.taskNum),
    })
    toggleEditModal()
  }

  const loadWidgets = async () => {
    let pv = ""
    if (taskTypeId == TaskTypes.risk || taskTypeId == TaskTypes.portfolioRisk) {
      pv = "ManageRisks"
    }
    if (
      taskTypeId == TaskTypes.issue ||
      taskTypeId == TaskTypes.portfolioIssue
    ) {
      pv = "ManageIssues"
    }
    if (pv !== "") {
      let w = await api.getWidgetsByView(pv, scenarioId)
      setWidgets(w)
    }
  }

  const uploadTasks = async file => {
    return await api.uploadTasks(alternativeId, file)
  }

  const expandAll = () => {
    if (ganttRef.current) {
      ganttRef.current.expandAll()
    }
  }

  const collapseAll = () => {
    if (ganttRef.current) {
      ganttRef.current.collapseAll()
    }
  }

  return (
    <React.Fragment>
      <div className="d-flex-row jc-space-between ai-center mb-3">
        <div className="d-flex-row ai-center" style={{ gap: 10 }}>
          {/* <button className="btn btn-primary " onClick={addTask}>
            Add Task
          </button> */}

          <button className="btn btn-primary " onClick={toggleUploadTasksModal}>
            Upload Tasks
          </button>
          {displayAs == 0 && (
            <>
              <button className="btn btn-outline-primary" onClick={expandAll}>
                Expand all
              </button>
              <button className="btn btn-outline-primary" onClick={collapseAll}>
                Collapse all
              </button>
            </>
          )}
        </div>

        <div>
          <ButtonGroup>
            <button
              className={`btn  ${
                displayAs == 3 ? " btn-primary" : " btn-outline-secondary"
              }`}
              onClick={() => setDisplayAs(3)}
            >
              Summary
            </button>
            <button
              className={`btn  ${
                displayAs == 0 ? " btn-primary" : " btn-outline-secondary"
              }`}
              onClick={() => setDisplayAs(0)}
            >
              Gantt
            </button>
            <button
              className={`btn ${
                displayAs == 1 ? " btn-primary" : "btn-outline-secondary"
              }`}
              onClick={() => setDisplayAs(1)}
            >
              Calendar
            </button>
            <button
              className={`btn ${
                displayAs == 2 ? " btn-primary" : "btn-outline-secondary"
              }`}
              onClick={() => setDisplayAs(2)}
            >
              Kanban
            </button>
          </ButtonGroup>
        </div>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <>
          {displayAs == 0 && (
            <GanttTaskTableNew
              taskType={taskType}
              flatTaskList={flatTaskList}
              setFlatTaskList={setFlatTaskList}
              editTask={editTask}
              displayAs={displayAs}
              ganttRef={ganttRef}
              holidays={holidays}
              alternative={alternative}
            />
          )}
          {displayAs == 1 && (
            <SchedulerTaskTable
              tasks={flatTaskList}
              loadTasks={loadTasks}
              editTask={editTask}
            />
          )}
          {displayAs == 2 && (
            <KanbanTaskTable
              displayAs={displayAs}
              tasks={flatTaskList}
              setTasks={setFlatTaskList}
              loadTasks={loadTasks}
              editTask={editTask}
            />
          )}
          {displayAs == 3 && (
            <SummarySection
              alternative={alternative}
              scenarioId={scenarioId}
              changeAlternativeProp={changeAlternativeProp}
              fieldConfigItems={fieldConfigItems}
              hasProjectPlanError={hasProjectPlanError}
            />
          )}
        </>
      )}
      <EditTaskModal
        isEditModalVisible={showEditModal}
        toggleEditModal={toggleEditModal}
        taskToEdit={taskToEdit}
        flatTaskList={flatTaskList}
        setTaskToEdit={setTaskToEdit}
        holidays={holidays}
        // reload={loadTasks}
      />

      <UploadModal
        isOpen={showUploadTasksModal}
        toggleModal={toggleUploadTasksModal}
        text="Upload a file containing the project schedule"
        callback={uploadTasks}
        fileType="Project"
        reload={loadTasks}
      />
    </React.Fragment>
  )
}

const SummarySection = ({
  alternative,
  scenarioId,
  changeAlternativeProp,
  fieldConfigItems,
  hasProjectPlanError,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [projectStatusOptions, setProjectStatusOptions] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let sts = await api.getList(currentUser.userID, "ProjectStatus")
    setProjectStatusOptions(sts)
  }

  const radioButtonProperties = [
    { text: "Project Scope", prop: "projectScope" },
    { text: "Project Schedule", prop: "projectSchedule" },
    { text: "Project Cost", prop: "projectCost" },
    { text: "Project Quality", prop: "projectQuality" },
    { text: "Project Staffing", prop: "projectStaffing" },
  ]

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
    type: "Expand",
  }

  if (alternative == undefined || alternative == null) return null
  return (
    <>
      <Row id="healthAndStatusContainer">
        <Col md="12">
          <div className="divider"></div>
          <div>
            <h5>Project Health and Status</h5>
          </div>
        </Col>
        <Col md="6">
          <div className="mb-3">
            {radioButtonProperties.map((r, idx) => (
              <HealthAndStatusRadioButtons
                key={idx}
                prop={r.prop}
                value={alternative[r.prop]}
                onChange={changeAlternativeProp}
                fieldConfigItems={fieldConfigItems}
              />
            ))}
            {fieldConfigItems.find(x => x.fieldName == "ProjectOverall") !=
              undefined && (
              <div
                className="mb-3"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Label style={{ width: "300px" }}>
                  Project Overall Health (Auto-Calculated)
                </Label>
                <div className="form-check">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id={"health1"}
                    checked={
                      radioButtonProperties.find(
                        x => alternative[x.prop] == 1
                      ) !== undefined
                    }
                    disabled={true}
                  />
                  <Label
                    className="form-check-label"
                    style={{ color: "red", marginLeft: "5px" }}
                  >
                    <b>Red</b>
                  </Label>
                </div>
                <div className="form-check">
                  <Input
                    style={{ marginLeft: "10px" }}
                    type="radio"
                    className="form-check-input"
                    id={"health2"}
                    checked={
                      radioButtonProperties.find(
                        x => alternative[x.prop] == 1
                      ) == undefined &&
                      radioButtonProperties.find(
                        x => alternative[x.prop] == 2
                      ) !== undefined
                    }
                    disabled={true}
                  />
                  <Label
                    className="form-check-label"
                    style={{ color: "gold", marginLeft: "5px" }}
                  >
                    <b>Yellow</b>
                  </Label>
                </div>
                <div className="form-check">
                  <Input
                    style={{ marginLeft: "10px" }}
                    type="radio"
                    className="form-check-input"
                    id={"health3"}
                    checked={
                      radioButtonProperties.find(
                        x => alternative[x.prop] == 1
                      ) == undefined &&
                      radioButtonProperties.find(
                        x => alternative[x.prop] == 2
                      ) == undefined
                    }
                    disabled={true}
                  />
                  <Label
                    className="form-check-label"
                    style={{ color: "green", marginLeft: "5px" }}
                  >
                    <b>Green</b>
                  </Label>
                </div>
              </div>
            )}
          </div>
          <div className="mb-3">
            <Label className="form-label">
              Project Health and Status Comments
            </Label>
            <TextareaAutosize
              className="form-control"
              minRows={2}
              placeholder="Briefly summarize the cause and plan to address each yellow or red rating, if any."
              onChange={e =>
                changeAlternativeProp("projectHealthComments", e.target.value)
              }
              value={alternative.projectHealthComments}
            />
            {hasProjectPlanError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Please provide the cause and plan to address each yellow or red
                rating.
              </div>
            )}
          </div>
        </Col>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Project Cost: Baseline</Label>
            <p>{currencyFormatter.format(alternative.plannedProjectCost)}</p>
          </div>
          <div className="mb-3">
            <Label className="form-label">
              Project Cost: Inception-to-Date (ITD)
            </Label>
            <CurrencyInput
              className="form-control"
              value={alternative.investmentToDate}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("investmentToDate", values.float)
              }}
              prefix={"$"}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">
              Project Cost: Estimate-to-Complete (ETC)
            </Label>
            <CurrencyInput
              className="form-control"
              value={alternative.estimatedCostToComplete}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("estimatedCostToComplete", values.float)
              }}
              prefix={"$"}
            />
          </div>
          <AlternativeConfigField
            label="% Complete"
            value={alternative.percentComplete}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            type="text"
            prop={"percentComplete"}
          />

          <div className="mb-3">
            <Label className="form-label">Project Status</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.projectState || -1}
              onChange={e => {
                changeAlternativeProp("projectState", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {projectStatusOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <AlternativeConfigField
            label="PM System Project Status"
            value={alternative.pmProjectStatus}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            prop={"pmProjectStatus"}
            type="text"
          />
          <AlternativeConfigField
            label="PM System Finish Date"
            value={alternative.pmFinishDate}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            prop={"pmFinishDate"}
            type="date"
            format="date"
          />
          <div className="mb-3">
            <Label className="form-label">Estimated Completion Date</Label>
            <Input
              name="estimatedCompletionDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "estimatedCompletionDate",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.estimatedCompletionDate) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.estimatedCompletionDate).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Progress</Label>

            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              value={alternative.projectProgress}
              change={e => {
                changeAlternativeProp("projectProgress", e.value)
              }}
            >
              <Inject
                services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
              />
            </RichTextEditorComponent>
          </div>
        </Col>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Plans</Label>

            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              value={alternative.projectPlans}
              change={e => {
                changeAlternativeProp("projectPlans", e.value)
              }}
            >
              <Inject
                services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
              />
            </RichTextEditorComponent>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProjectPlanSection
