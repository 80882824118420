import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row, FormGroup } from "reactstrap"
import api from "services/api.service"
import anychart from "anychart"
import Loader from "react-loaders"
import colors from "utils/colors"
import GanttLegend from "assets/images/GanttLegend.png"
import { userService } from "services/user.service"
import useFetcher from "hooks/useFetcher"

const RoadmapChartModal = ({ isOpen, toggleModal, alternatives, scenario }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [showFull, setShowFull] = useState(null)
  const [showGrid, setShowGrid] = useState(null)
  const [timeScale, setTimeScale] = useState(null)
  const [chartA, setChart] = useState(null)

  const [selectedMilestoneDisplayId, setSelectedMilestoneDisplayId] =
    useState(0)
  const [milestoneDisplayOptions, setMilestoneDisplayOptions] = useState([])

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    loadMilestoneDisplayOptions()
  }, [])

  useEffect(() => {
    if (isOpen) {
      showGantt()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      showGantt()
    }
  }, [showFull, showGrid, timeScale, selectedMilestoneDisplayId])

  const loadMilestoneDisplayOptions = async () => {
    let list = await api.getList(currentUser.userID, "RoadmapType")
    setMilestoneDisplayOptions(list)
  }

  const showGantt = () => {
    let chartName = ""
    let chartRendererFunc = null
    if (!showFull) {
      chartName = "BCD_Milestone"
      chartRendererFunc = renderGanttChart
    } else {
      chartName = "BCD_Milestone_Task"
      chartRendererFunc = renderGanttChart2
    }

    setIsLoading(true)
    let altIds = alternatives.map(x => x.alternativeid).join(",")
    api
      .getAlternativeListChart(altIds, chartName, selectedMilestoneDisplayId)
      .then(
        resp => {
          setIsLoading(false)
          chartRendererFunc(resp, "gantt")
        },
        err => setIsLoading(false)
      )
  }

  const downloadName = "Roadmap Summary"
  const exportChartAsPDF = () => {
    if (!chartA) {
      return
    }

    chartA.saveAsPdf({ filename: downloadName })
  }

  const exportChartAsPNG = () => {
    if (!chartA) {
      return
    }

    chartA.saveAsPng({ filename: downloadName })
  }

  const loadChart = async () => {
    setIsLoading(true)
    let altIds = alternatives.map(x => x.alternativeid).join(",")
    api.getAlternativeListChart(altIds, "BCD_Milestone").then(
      resp => {
        setIsLoading(false)
        renderGanttChart(resp, "gantt")
      },
      err => setIsLoading(false)
    )
  }

  const loadChart2 = async () => {
    setIsLoading(true)
    let altIds = alternatives.map(x => x.alternativeid).join(",")
    api.getAlternativeListChart(altIds, "BCD_Milestone_Task").then(
      resp => {
        setIsLoading(false)
        renderGanttChart2(resp, "gantt")
      },
      err => setIsLoading(false)
    )
  }

  const renderGanttChart =async (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 100 + 25 * items
    ganttDiv.style.height = gh + "px"

    var newColumn = chart.dataGrid().column(0)
    newColumn.width(50)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(1)
    newColumn.width(400)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    if (showGrid) {
      let planningCategoryLabel = await api.getEnterpriseLabel(scenario?.enterpriseID ?? currentUser.enterpriseID, "Planning Category")
      var newColumn = chart.dataGrid().column(2)
      newColumn.width(250)
      newColumn.title(planningCategoryLabel)
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%planningcategory}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(3)
      newColumn.width(150)
      newColumn.title("Project Manager")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%projectmanager}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(4)
      newColumn.width(150)
      newColumn.title("BRM")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%brm}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(5)
      newColumn.width(90)
      newColumn.title("Start")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().useHtml(true)
      newColumn.labels().fontColor("#000000")
      newColumn
        .labels()
        .format(
          "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn.depthPaddingMultiplier(20)
      newColumn.collapseExpandButtons(true)

      var newColumn2 = chart.dataGrid().column(6)
      newColumn2.width(90)
      newColumn2.title("Finish")
      newColumn2.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn2.title().fontWeight(600)
      newColumn2.labels().useHtml(true)
      newColumn2.labels().fontColor("#000000")
      newColumn2
        .labels()
        .format(
          "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn2.depthPaddingMultiplier(20)
      newColumn2.collapseExpandButtons(true)
    }

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)
    dgTooltip.format(
      "<span>" +
        "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
        "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
        "% Complete: {%progress}<br><br>" +
        "Planned Requirements Completion: {%RequirementsDate}<br>" +
        "Planned Market Research Completion: {%MarketResearchDate}<br>" +
        "Planned Procurement Package Completion: {%ProcurementPackageDate}<br>" +
        "Planned Procurement Completion: {%ProcurementDate}<br>"
      //"Funds Needed By: {%FundsByDate}<br>"
    )
    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}<br><br>" +
          "Planned Requirements Completion: {%RequirementsDate}<br>" +
          "Planned Market Research Completion: {%MarketResearchDate}<br>" +
          "Planned Procurement Package Completion: {%ProcurementPackageDate}<br>" +
          "Planned Procurement Completion: {%ProcurementDate}<br>"
        //"Funds Needed By: {%FundsByDate}<br>"
      )
    //var header = chart.getTimeline().header()
    //var mlevel = header.level(2)
    //mlevel.enabled(false);
    // set zoom levels of the scale

    if (timeScale == "YMW") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "week", count: 1 },
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])
    } else if (timeScale == "YM") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])
    } else if (timeScale == "YQ") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])
    } else {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])
    }

    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.defaultRowHeight(25)

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header();
      header.level(2).format(function(){return +this.value + 1});
    }

    chart.xScale().fiscalYearStartMonth(scenario.fyMonth) //(<%=HiddenFYMonth.Value%>);
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    //chart.fitAll()

    if (timeScale == "YQ") {
      chart.fitAll()
    } else {
      chart.zoomTo(Date.UTC(year, 1, 1), Date.UTC(year + 2, 11, 30))
    }

    if (showGrid) {
      chart.splitterPosition(800)
    } else {
      chart.splitterPosition(450)
    }

    setChart(chart)
  }

  const renderGanttChart2 = async (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 100 + 125 * items
    ganttDiv.style.height = gh + "px"

    var newColumn = chart.dataGrid().column(0)
    newColumn.width(50)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(1)
    newColumn.width(400)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    if (showGrid) {
      let planningCategoryLabel = await api.getEnterpriseLabel(scenario?.enterpriseID ?? currentUser.enterpriseID, "Planning Category")
      var newColumn = chart.dataGrid().column(2)
      newColumn.width(250)
      newColumn.title(planningCategoryLabel)
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%planningcategory}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(3)
      newColumn.width(150)
      newColumn.title("Project Manager")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%projectmanager}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(4)
      newColumn.width(150)
      newColumn.title("BRM")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%brm}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(5)
      newColumn.width(90)
      newColumn.title("Start")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().useHtml(true)
      newColumn.labels().fontColor("#000000")
      newColumn
        .labels()
        .format(
          "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn.depthPaddingMultiplier(20)
      newColumn.collapseExpandButtons(true)

      var newColumn2 = chart.dataGrid().column(6)
      newColumn2.width(90)
      newColumn2.title("Finish")
      newColumn2.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn2.title().fontWeight(600)
      newColumn2.labels().useHtml(true)
      newColumn2.labels().fontColor("#000000")
      newColumn2
        .labels()
        .format(
          "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn2.depthPaddingMultiplier(20)
      newColumn2.collapseExpandButtons(true)
    }

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)
    /*     dgTooltip.format(
      "<span>" +
        "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
        "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
        "% Complete: {%progress}<br><br>" 
    ) */
    chart.getTimeline().tooltip().useHtml(true)
    chart.getTimeline().tooltip()
    /*       .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}<br><br>"
      ) */
    //var header = chart.getTimeline().header()
    //var mlevel = header.level(2)

    chart
      .dataGrid()
      .tooltip()
      .format(function () {
        var duration = (this.actualEnd - this.actualStart) / 1000 / 3600 / 24
        var startDate = anychart.format.dateTime(this.actualStart, "dd MMM")
        var endDate = anychart.format.dateTime(this.actualEnd, "dd MMM")
        var progress = Math.round(this.progress * 100 * 100) / 100 + "%"

        var parentText =
          "<span>" +
          startDate +
          " - " +
          endDate +
          "<br><br>" +
          "% Complete: " +
          progress +
          "</span>"

        var milestoneText = "<span>" + startDate + "</span>"

        // identify the task type and display the corresponding text
        if (duration == 0) {
          return milestoneText
        } else {
          return parentText
        }
      })

    chart
      .getTimeline()
      .tooltip()
      .format(function () {
        var duration = (this.actualEnd - this.actualStart) / 1000 / 3600 / 24
        var startDate = anychart.format.dateTime(
          this.actualStart,
          "dd MMM yyyy"
        )
        var endDate = anychart.format.dateTime(this.actualEnd, "dd MMM yyyy")
        var progress = Math.round(this.progress * 100 * 100) / 100 + "%"

        var parentText =
          "<span>" +
          startDate +
          " - " +
          endDate +
          "<br><br>" +
          "% Complete: " +
          progress +
          "</span>"

        var milestoneText = "<span>" + startDate + "</span>"

        // identify the task type and display the corresponding text
        if (duration == 0) {
          return milestoneText
        } else {
          return parentText
        }
      })

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")

    chart.dataGrid().column(1).depthPaddingMultiplier(20)

    //mlevel.enabled(false);
    // set zoom levels of the scale
    if (timeScale == "YMW") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "week", count: 1 },
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])
    } else if (timeScale == "YM") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "year", count: 1 },
            { unit: "month", count: 1 },
          ],
        ])
    } else if (timeScale == "YQ") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([[{ unit: "quarter", count: 1 }]])
    } else {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])
    }
    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.defaultRowHeight(25)

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header();
      header.level(2).format(function(){return +this.value + 1});
    }

    chart.xScale().fiscalYearStartMonth(scenario.fyMonth) //(<%=HiddenFYMonth.Value%>);
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    //chart.fitAll()
    chart.zoomTo(Date.UTC(year, 1, 1), Date.UTC(year + 2, 11, 30))

    if (showGrid) {
      chart.splitterPosition(800)
    } else {
      chart.splitterPosition(450)
    }


    setChart(chart)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Roadmap
        </h5>

        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-3">
          <Col>
            <h5 className="text-center">Roadmap</h5>

            <div>
              <div style={{ fontSize: "20px", textAlign: "right" }}>
                <i className="fas fa-file-pdf" onClick={exportChartAsPDF}></i>
                &nbsp;&nbsp;
                <i className="fas fa-file-image" onClick={exportChartAsPNG}></i>
              </div>
            </div>
            <Row className="mb-3">
              <Col md="3">
                <div>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={showFull}
                      /*                     style={{
                      backgroundColor: {showFull}
                        ? "green"
                        : "transparent",
                    }} */
                      onClick={e => {
                        setShowFull(!showFull)
                        //showGantt()
                      }}
                    />
                    <Label check style={{ marginLeft: "10px" }}>
                      {!showFull && <div>Detailed View</div>}
                      {showFull && <div>Summary View</div>}
                    </Label>
                  </FormGroup>

                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={showGrid}
                      onClick={e => {
                        setShowGrid(!showGrid)
                        // showGantt()
                      }}
                    />
                    <Label check style={{ marginLeft: "10px" }}>
                      {showGrid && <div>Hide data grid</div>}
                      {!showGrid && <div>Show data grid</div>}
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Label className="form-label">Timescale</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    style={{ width: "250px" }}
                    value={timeScale}
                    onChange={e => {
                      setTimeScale(e.target.value)
                      // showGantt()
                    }}
                  >
                    <option value="YQM">Year Quarter Month</option>
                    <option value="YMW">Year Month Week</option>
                    <option value="YM">Year Month</option>
                    <option value="YQ">Year Quarter</option>
                  </select>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Label className="form-label">Milestone Display</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    style={{ width: "250px" }}
                    value={selectedMilestoneDisplayId}
                    onChange={e => {
                      setSelectedMilestoneDisplayId(e.target.value)
                      // showGantt()
                    }}
                  >
                    {milestoneDisplayOptions.map((m, idx) => (
                      <option key={idx} value={m.listItemID}>
                        {m.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}

            {!showFull && (
              <div>
                <img src={GanttLegend} alt="" height="80" />
              </div>
            )}

            <div id="gantt" style={{ width: "100%", height: "100%" }}></div>
          </Col>
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default RoadmapChartModal
