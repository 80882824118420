import { DynamicFormFieldRenderer } from "components/custom/DynamicForm/View/DynamicForm"
import React, { useCallback, useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"

const BatchUpdateModal = ({ isOpen, toggleModal, scenario, alternatives, reload }) => {
  const currentUser = userService.getLoggedInUser()
  const [formFields, setFormFields] = useState([])
  const [selectedFormField, setSelectedFormField] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setFormFields([])
      setSelectedFormField(null)
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let f = await api.getFormFieldsScenario(
      scenario.enterpriseID,
      currentUser.userID,
      scenario.scenarioID
    )
    setFormFields(f)
    setIsLoading(false)
  }

  const save = async () => {
    // let ff = {...selectedFormField}
    // if(ff.field?.dataType?.toLowerCase() == 'bool' && !isNullOrEmpty(ff.fieldData) && isNullOrEmpty(ff.fieldData.value)){
    //   ff.fieldData.value = false
    // }
    // console.log(ff)
    await api.updateAlternativesForFormField(
      alternatives.map(x => x.alternativeid),
      selectedFormField
    )
    reload && reload()
    toast.success("Alternatives updated")
    toggleModal()
  }

  const changeFormFieldValue = useCallback(
    (formFieldId, value) => {
      setSelectedFormField(prev => {
        return {
          ...prev,
          fieldData: {
            ...prev.fieldData,
            value: value,
          },
          isDirty: true,
        }
      })
    },
    [setSelectedFormField]
  )

  //   value => {
  //     setSelectedFormField({
  //       ...selectedFormField,
  //       fieldData: {
  //         ...selectedFormField.fieldData,
  //         value: value,
  //       },
  //       isDirty: true,
  //     })
  //   }

  return (
    <>
      <Modal
        backdrop="static"
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Batch Update Data
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <div className="mb-3">
              <Label className="form-label">Field to Update</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                //   value={selectedListId}
                onChange={e => {
                  let ff = formFields.find(x => x.formFieldId == e.target.value)
                  if (ff != undefined) {
                    setSelectedFormField({
                      ...ff,
                      fieldData: { ...ff.fieldData, value: ff.field?.dataType?.toLowerCase() == 'bool' ? false : "", isDirty: false },
                    })
                  } else {
                    setSelectedFormField(null)
                  }
                }}
              >
                <option value="-1">Select Field...</option>
                {formFields.map((x, idx) => (
                  <option key={idx} value={x.formFieldId}>
                    {x.formFieldLabel}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedFormField != null && (
            <DynamicFormFieldRenderer
              ff={selectedFormField}
              changeFormFieldValue={changeFormFieldValue}
              col="12"
            />
          )}
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
            disabled={selectedFormField == null}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

export default BatchUpdateModal
