import { swalWithConfirmButton } from "components/custom/swal"
import { Tooltip } from "@material-ui/core"
import Widgets from "components/Widgets"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import OptimizationAlert from "../Components/optimizationAlert"
import OptimizeModal from "../OptimizationSingle/OptimizeModal"
import ParetoChartModal from "./ParetoChart"

const OptimizationMulti = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  const [isOptimizeModalVisible, setIsOptimizeModalVisible] = useState(false)
  const [isChartModalVisible, setIsChartModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOptModelLoading, setOptModelIsLoading] = useState(false)
  const [isParetoModalVisible, setIsParetoModalVisible] = useState(false)
  const [optModel, setOptModel] = useState(null)

  const [paretoItems, setParetoItems] = useState([])
  const [paretoSummary, setParetoSummary] = useState([])
  const [showDetails, setShowDetails] = useState(true)
  const [showSummary, setShowSummary] = useState(false)
  const [selectedSortBy, setSelectedSortBy] = useState("Rating")

  const [optResults, setOptResults] = useState([])
  const [scenario, setScenario] = useState(null)
  const [optSeries, setOptSeries] = useState(null)
  const [mixChart, setMixChart] = useState(null)
  const [widgets, setWidgets] = useState([])

  const toggleParetoModal = () => setIsParetoModalVisible(!isParetoModalVisible)

  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "optimizationMulti",
      })
    )
    setSelectedSortBy("Rating")
  }, [])

  useEffect(() => {
    if (selectedSortBy !== "" && optModel !== null) {
      loadOptResults(optModel)
    }
  }, [selectedSortBy])

  useEffect(() => {
    loadData()
  }, [optObjFin])

  const loadData = async () => {
    setIsLoading(true)

    try {
      let scen = await api.getScenario(scenarioId)
      setScenario(scen)
      let items = await api.getParetoItems(
        optObjFin != null && optObjFin.opt != null
          ? optObjFin.opt.optModelID
          : scen.currentOptModelID
      )
      setParetoItems(items)
      await loadWidgets(scen)
      let opt = await loadOptModel(
        optObjFin != null && optObjFin.opt != null
          ? optObjFin.opt.optModelID
          : scen.currentOptModelID
      )
      await loadSummary(opt)
      await loadOptResults(opt)

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadWidgets = async scen => {
    let w = await api.getWidgetsByView(
      "OptimizationSingle",
      optObjFin != null && optObjFin.opt != null
        ? optObjFin.opt.optModelID
        : scen == undefined
        ? scenario.currentOptModelID
        : scen.currentOptModelID
    )
    setWidgets(w)
  }

  const loadOptModel = async optModelId => {
    setOptModelIsLoading(true)
    let data = await api.getOptModel(optModelId)
    setOptModel(data)
    setOptModelIsLoading(false)
    return data
  }

  const loadSummary = async optModel => {
    let data = await api.getParetoGridDataSummary(
      optModel.optModelID,
      optModel.objModelID,
      optModel.finModelID
    )
    setParetoSummary(data)
  }

  const loadOptResults = async opt => {
    setIsLoading(true)
    let data = await api.getParetoGridData(
      opt?.optModelID ?? 0,
      opt?.objModelID ?? 0,
      opt?.finModelID ?? 0,
      selectedSortBy
    )
    setOptResults(data)
    setIsLoading(false)
  }

  const saveOptimization = async resourceGroupResults => {
    try {
      let itemsToUpdate = resourceGroupResults.filter(x => x.isDirty)
      if (itemsToUpdate.length > 0) {
        let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
          return await api.updateResourceGroupResult(currentUser.userID, x)
        })
        await Promise.all(itemsToUpdateTasks.map(t => t()))
      }
      await api.updateOptModel(currentUser.userID, optModel)
      let res = await api.runMultiOptimization(currentUser.userID, optModel)
      if (res.toString().toLowerCase() == "optimized") {
        // loadOptResults(optModel)
        loadData()
        toggleOptimizeModal()
      } else {
        swalWithConfirmButton.fire({
          title: res,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const loadMixChart = async optParetoItemId => {
    let chart = await api.getMixChartMulti(optParetoItemId)
    setMixChart(chart)
    let opts = {
      series: chart.chartTable.map(c => c.costContribution),
      options: {
        chart: {
          width: "100%",
          type: "pie",
          donut: {
            size: "65%",
          },
        },
        labels: chart.chartTable.map(c => c.objective),
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return currencyFormatter.format(val)
            },
          },
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    }
    setOptSeries(opts)
  }

  const toggleChartModal = () => setIsChartModalVisible(!isChartModalVisible)

  const toggleOptimizeModal = () =>
    setIsOptimizeModalVisible(!isOptimizeModalVisible)

  const changeOptModelProp = (prop, value) => {
    setOptModel({
      ...optModel,
      [prop]: value,
    })
  }

  const openOptimizeModal = () => {
    toggleOptimizeModal()
    loadOptModel(scenario.currentOptModelID)
  }

  const openChartModal = optParetoItemId => {
    toggleChartModal()
    loadMixChart(optParetoItemId)
  }

  const formattedCost =
    optModel == null
      ? 0
      : `${Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(optModel.parStart)} - ${Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(optModel.parEnd)}`

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setOptResults(
      direction == "asc"
        ? sortAsc(optResults, prop)
        : sortDesc(optResults, prop)
    )
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }
  const sortDesc = (data, prop) => {
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Widgets widgets={widgets} marginBottom="20px" />
                  {optModel != null && (
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>
                              <b>Cost Constraint</b>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h1>${formattedCost}</h1>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "15px",
                                }}
                              >
                                <span>
                                  Optimizing: <b>{optModel.optimizeOn}</b>
                                </span>
                                <span>
                                  Type: <b>{optModel.minMax}</b>
                                </span>
                                <span>
                                  Cost Variable:{" "}
                                  <b>{optModel.costVariableName}</b>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>
                              <b>Other Constraints</b>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  Musts:{" "}
                                  <b>
                                    {optModel.useMust == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numMust})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Must Nots:{" "}
                                  <b>
                                    {optModel.useCant == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numCant})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Partials:{" "}
                                  <b>
                                    {optModel.usePartial == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numPartial})
                                      </span>
                                    )}
                                  </b>
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "15px",
                                }}
                              >
                                <span>
                                  Group:{" "}
                                  <b>
                                    {optModel.useResource == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numGroup})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Resource:{" "}
                                  <b>
                                    {optModel.useResourceGroups == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numResource})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Dependencies:{" "}
                                  <b>
                                    {optModel.useDependencies == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numDependency})
                                      </span>
                                    )}
                                  </b>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              <b>Scope / Models</b>
                            </span>
                            <span>
                              Scope: <b>{optModel.scope}</b>
                            </span>
                            <span>
                              Objective Model:{" "}
                              <b>
                                {optModel.objModelName == null
                                  ? "None"
                                  : optModel.objModelName}
                              </b>
                            </span>
                            <span>
                              Financial Model: <b>{optModel.finModelName}</b>
                            </span>
                          </div>
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={openOptimizeModal}
                            >
                              Modify
                            </button>

                            <button
                              className="btn btn-primary save-user"
                              onClick={toggleParetoModal}
                            >
                              Show Pareto Chart
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <OptimizationAlert optModel={optModel} />
                  <Row style={{ marginTop: "10px" }}>
                    <Col style={{ overflowX: "auto" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Label>Sort by: </Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedSortBy}
                          style={{ width: "150px", marginLeft: "10px" }}
                          onChange={e => {
                            setSelectedSortBy(e.target.value)
                          }}
                        >
                          <option value="ID">ID</option>
                          <option value="Name">Name</option>
                          <option value="Rating">Rating</option>
                          <option value="Cost">Cost</option>
                          <option value="CB">CB</option>
                        </select>
                        <Input
                          id="showDetails"
                          type="checkbox"
                          style={{ marginLeft: "10px", marginTop: "0px" }}
                          checked={showDetails}
                          onClick={() => setShowDetails(!showDetails)}
                        />
                        <Label
                          className="form-check-label"
                          for="showDetails"
                          style={{ marginLeft: "10px" }}
                        >
                          Show Details
                        </Label>
                        <Input
                          id="showSummary"
                          type="checkbox"
                          style={{ marginLeft: "10px", marginTop: "0px" }}
                          checked={showSummary}
                          onClick={() => setShowSummary(!showSummary)}
                        />
                        <Label
                          className="form-check-label"
                          for="showSummary"
                          style={{ marginLeft: "10px" }}
                        >
                          Show Summary
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col style={{ overflowX: "auto" }}>
                      {isLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      {!isLoading && optResults.length > 0 && (
                        <table
                          className="table table-bordered lower-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{ minWidth: "60px" }}
                                onClick={() => handleSort("alternativeNum")}
                              >
                                ID {getSortIcon("alternativeNum")}
                              </th>
                              {scenarioId < 0 && (
                                <th
                                  onClick={() => handleSort("scenario")}
                                  style={{ minWidth: "200px" }}
                                >
                                  Portfolio {getSortIcon("scenario")}
                                </th>
                              )}
                              <th
                                onClick={() => handleSort("alternative")}
                                style={{ maxWidth: "300px" }}
                              >
                                Project {getSortIcon("alternative")}
                              </th>
                              <th onClick={() => handleSort("version")}>
                                Version {getSortIcon("version")}
                              </th>
                              {showDetails && (
                                <>
                                  <th
                                    onClick={() => handleSort("benefitScore")}
                                    style={{ minWidth: "120px" }}
                                  >
                                    Benefit Score {getSortIcon("benefitScore")}
                                  </th>
                                  <th onClick={() => handleSort("groupRating")}>
                                    Group Rating {getSortIcon("groupRating")}
                                  </th>
                                  <th onClick={() => handleSort("cost")}>
                                    Cost Variable {getSortIcon("cost")}
                                  </th>
                                  <th onClick={() => handleSort("costBenefit")}>
                                    Benefit Score / Total Cost{" "}
                                    {getSortIcon("costBenefit")}
                                  </th>
                                  <th onClick={() => handleSort("mustHave")}>
                                    Must {getSortIcon("mustHave")}
                                  </th>
                                  <th onClick={() => handleSort("cantHave")}>
                                    Must Not {getSortIcon("cantHave")}
                                  </th>
                                  <th onClick={() => handleSort("partial")}>
                                    Partial {getSortIcon("partial")}
                                  </th>
                                  <th>Resources</th>
                                  <th>Group Constraints</th>
                                </>
                              )}
                              {paretoItems.length > 0 &&
                                paretoItems.map((x, idx) => {
                                  return (
                                    <th key={idx}>
                                      <a
                                        href="#"
                                        onClick={e => {
                                          e && e.preventDefault()
                                          openChartModal(x.optModelParetoItemID)
                                        }}
                                      >
                                        {currencyFormatter.format(
                                          x.constraintAmount
                                        )}
                                      </a>
                                    </th>
                                  )
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {showSummary &&
                              paretoSummary.length > 0 &&
                              paretoSummary.map((r, idx) => {
                                return (
                                  <tr key={"aaa111" + idx}>
                                    <td></td>
                                    {scenarioId < 0 && <td></td>}
                                    <td>{r.item}</td>
                                    <td></td>
                                    {showDetails && (
                                      <>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </>
                                    )}
                                    {paretoItems.length > 0 &&
                                      paretoItems.map((x, idx2) => {
                                        return (
                                          <td key={idx2} className="text-right">
                                            {r[x.constraintAmount]}
                                          </td>
                                        )
                                      })}
                                  </tr>
                                )
                              })}
                            {showDetails &&
                              optResults.map((r, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{r.alternativeNum}</td>
                                    {scenarioId < 0 && <td>{r.scenario}</td>}
                                    <td
                                      style={{
                                        maxWidth: "300px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <b>{r.alternative}</b>
                                    </td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: r.version,
                                        }}
                                      ></div>
                                    </td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: r.benefitScore,
                                        }}
                                      ></div>
                                    </td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: r.groupRating,
                                        }}
                                      ></div>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {currencyFormatter.format(
                                        r.alternativeCost
                                      )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {r.costBenefit.toFixed(2)}
                                    </td>
                                    <td>
                                      <Input
                                        type="checkbox"
                                        checked={r.mustHave}
                                        readOnly={true}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="checkbox"
                                        checked={r.cantHave}
                                        readOnly={true}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="checkbox"
                                        checked={r.partial}
                                        readOnly={true}
                                      />
                                    </td>
                                    <td>
                                      <div className="one-line-elipsis">
                                        <Tooltip
                                          title={
                                            <h6>{r.resourceConstraints}</h6>
                                          }
                                        >
                                          <span>{r.resourceConstraints}</span>
                                        </Tooltip>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="one-line-elipsis">
                                        <Tooltip
                                          title={<h6>{r.groupConstraints}</h6>}
                                        >
                                          <span>{r.groupConstraints}</span>
                                        </Tooltip>
                                      </div>
                                    </td>
                                    {paretoItems.length > 0 &&
                                      paretoItems.map((x, idx) => {
                                        return (
                                          <td
                                            key={idx}
                                            style={{
                                              textAlign: "right",
                                              backgroundColor:
                                                r[x.constraintAmount] == 0 ||
                                                r[x.constraintAmount] == "0.0%"
                                                  ? "transparent"
                                                  : "lightgreen",
                                              color:
                                                r[x.constraintAmount] == 0 ||
                                                r[x.constraintAmount] == "0.0%"
                                                  ? "initial"
                                                  : "black",
                                            }}
                                          >
                                            {r[x.constraintAmount]}
                                          </td>
                                        )
                                      })}
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OptimizeModal
        scenario={scenario}
        isOpen={isOptimizeModalVisible}
        toggleModal={toggleOptimizeModal}
        optModel={optModel}
        isOptModelLoading={isOptModelLoading}
        saveOptimizationSingle={saveOptimization}
        changeOptModelProp={changeOptModelProp}
        showInterval={true}
        reloadData={loadWidgets}
      />

      <ParetoChartModal
        isOpen={isParetoModalVisible}
        toggleModal={toggleParetoModal}
        scenario={scenario}
      />

      <Modal
        backdrop="static"
        isOpen={isChartModalVisible}
        size="lg"
        toggle={() => {
          toggleChartModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Selection Mix and Balance by Objective
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleChartModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {optSeries != null && (
            <div className="mb-3">
              <ReactApexChart
                options={optSeries.options}
                series={optSeries.series}
                type="pie"
                width="100%"
                height="600px"
              />
            </div>
          )}

          {mixChart !== null && (
            <div className="mb-3">
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "60%" }}>Objective</th>
                    <th style={{ width: "20%" }}>Cost Allocation</th>
                    <th style={{ width: "20%" }}>Percent (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {mixChart.chartTable.map((m, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{m.objective}</td>
                        <td style={{ textAlign: "right" }}>
                          {currencyFormatter.format(m.costContribution)}
                        </td>
                        <td>{m.percentContribution.toFixed(2)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleChartModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default OptimizationMulti
