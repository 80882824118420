import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { changeBubbleScatterChartOptions } from "store/actions"
import colors from "utils/colors"

const AltScatterChartModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeList,
  optObjFin,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const { bubbleScatterChartOptions } = useSelector(state => ({
    bubbleScatterChartOptions:
      state.bubbleScatterChartOptions.bubbleScatterChartOptions,
  }))

  const changeBubbleScatterChartOption = (prop, val) => {
    dispatch(
      changeBubbleScatterChartOptions({
        ...bubbleScatterChartOptions,
        [prop]: val,
      })
    )
  }

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    await loadChart()
  }

  const loadChart = async () => {
    setIsLoading(true)
    if (
      document.getElementById("chartdiv") !== undefined &&
      document.getElementById("chartdiv") !== null
    ) {
      document.getElementById("chartdiv").innerHTML = ""
    }
    let chartResponse = await api.getAltScatterChart(
      scenarioId,
      optObjFin != null && optObjFin.obj != null ? optObjFin.obj.objModelID : 0,
      optObjFin != null && optObjFin.fin != null ? optObjFin.fin.finModelID : 0,
      alternativeList.join(","),
      bubbleScatterChartOptions.xAxisField,
      bubbleScatterChartOptions.showNames
    )
    let extraDataArray = chartResponse.chartData.split(",")
    let q1Color = extraDataArray
      .find(x => x.indexOf("Q1Color") > -1)
      .trim()
      .replace("Q1Color:", "")
    let q2Color = extraDataArray
      .find(x => x.indexOf("Q2Color") > -1)
      .trim()
      .replace("Q2Color:", "")
    let q3Color = extraDataArray
      .find(x => x.indexOf("Q3Color") > -1)
      .trim()
      .replace("Q3Color:", "")
    let q4Color = extraDataArray
      .find(x => x.indexOf("Q4Color") > -1)
      .trim()
      .replace("Q4Color:", "")
    let scoreName = extraDataArray
      .find(x => x.indexOf("ScoreName") > -1)
      .trim()
      .replace("ScoreName:", "")
    let costName = extraDataArray
      .find(x => x.indexOf("CostName") > -1)
      .trim()
      .replace("CostName:", "")
    let xFormat = extraDataArray
      .find(x => x.indexOf("XFormat") > -1)
      .trim()
      .replace("XFormat:", "")
    let xFormat2 = extraDataArray
      .find(x => x.indexOf("XFormat2") > -1)
      .trim()
      .replace("XFormat2:", "")
    let defaultMaxCost = extraDataArray
      .find(x => x.indexOf("DefaultMaxCost") > -1)
      .trim()
      .replace("DefaultMaxCost:", "")

    //var chart = anychart.quadrant(chartResponse.chartArray)

    //var dataStr = '[{"x": 250000, "value": 92.7800000, "ToolTip": "300-Enterprise Repository Integration with Smartsheet<br><br>Decision: On-Hold<br>Benefit: 92.78%<br>Cost: $250,000<br>Benefit / Cost Ratio: 0.0371", "size": 1, "id": "300", "normal": {"fill": "#7FB1B1", "stroke": "gray"}},{"x": 790000, "value": 84.9000000, "ToolTip": "174-Develop a Cyber Security Policy and Capability Roadmap<br><br>Decision: Pending<br>Benefit: 84.90%<br>Cost: $790,000<br>Benefit / Cost Ratio: 0.0107", "size": 1, "id": "174", "normal": {"fill": "#003399", "stroke": "gray"}}]'
    var dataStr = chartResponse.chartArray
    var data = JSON.parse(dataStr)
    var chart = anychart.quadrant(data)
    chart.quarters({
      rightTop: {
        fill: anychart.color.lighten(q2Color, 0),
        title: {
          text:
            bubbleScatterChartOptions.q2 !== ""
              ? bubbleScatterChartOptions.q2
              : `Quadrant 2: Higher ${scoreName}, Higher ${costName}`,
          padding: 10,
          fontFamily: "Inter",
          fontStyle: "italic",
          fontWeight: "bold",
        },
      },
      rightBottom: {
        fill: anychart.color.lighten(q4Color, 0),
        title: {
          text:
            bubbleScatterChartOptions.q4 !== ""
              ? bubbleScatterChartOptions.q4
              : `Quadrant 4: Lower ${scoreName}, Higher ${costName}`,
          padding: 10,
          fontFamily: "Inter",
          fontStyle: "italic",
          fontWeight: "bold",
        },
      },
      leftTop: {
        fill: anychart.color.lighten(q1Color, 0),
        title: {
          text:
            bubbleScatterChartOptions.q1 !== ""
              ? bubbleScatterChartOptions.q1
              : `Quadrant 1: Higher ${scoreName}, Lower ${costName}`,
          padding: 10,
          fontFamily: "Inter",
          fontStyle: "italic",
          fontWeight: "bold",
        },
      },
      leftBottom: {
        fill: anychart.color.lighten(q3Color, 0),
        title: {
          text:
            bubbleScatterChartOptions.q3 !== ""
              ? bubbleScatterChartOptions.q3
              : `Quadrant 3: Lower ${scoreName}, Lower ${costName}`,
          padding: 10,
          fontFamily: "Inter",
          fontStyle: "italic",
          fontWeight: "bold",
        },
      },
    })
    var series = chart.getSeries(0)
    series.labels().useHtml(true)
    series
      .labels()
      .fontFamily("Inter")
      .enabled(true)
      .format(function (e) {
        return "<span style='color:black;'>" + this.getData("id") + "</span>"
      })
    chart.xGrid(true)
    chart.yGrid(true)
    chart.xMinorGrid(true)
    chart.yMinorGrid(true)
    chart.title("")
    chart.xAxis().title({
      text: costName,
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "Inter",
      align: "center",
    })
    chart.yAxis().title({
      text: scoreName,
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "Inter",
      align: "center",
    })
    chart.xAxis(0, {
      ticks: true,
      labels: true,
      fontWeight: "bold",
      fontFamily: "Inter",
      fontSize: 16,
    })
    chart.yAxis(0, {
      ticks: true,
      labels: true,
      fontWeight: "bold",
      fontFamily: "Inter",
      fontSize: 16,
    })
    chart
      .yScale()
      .minimum(
        bubbleScatterChartOptions.yAxisAuto ? 0 : bubbleScatterChartOptions.minY
      )
    chart
      .yScale()
      .maximum(
        bubbleScatterChartOptions.yAxisAuto
          ? 100
          : bubbleScatterChartOptions.maxY
      )
    chart
      .xScale()
      .minimum(
        bubbleScatterChartOptions.xAxisAuto ? 0 : bubbleScatterChartOptions.minX
      )
    chart
      .xScale()
      .maximum(
        bubbleScatterChartOptions.maxX == 0 ||
          bubbleScatterChartOptions.xAxisAuto
          ? defaultMaxCost
          : bubbleScatterChartOptions.maxX
      )
    var padding = chart.padding()
    padding.right(100)
    var xLabels = chart.xAxis().labels()
    xLabels.format(function () {
      return (
        xFormat + anychart.format.number(this.value, 2, ".", ",") + xFormat2
      )
    })
    xLabels.fontSize(16)
    xLabels.fontWeight("bold")
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return this.value + "%"
    })
    yLabels.fontSize(16)
    yLabels.fontWeight("bold")
    chart.tooltip().useHtml(true)
    var tooltip = chart.tooltip()
    tooltip.titleFormat("{%id}")
    tooltip.format("{%ToolTip}")
    chart.container("chartdiv")
    chart.credits().enabled(false)

    yLabels.fontFamily("Inter")
    xLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
    setIsLoading(false)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Scatter Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col md="6" sm="6">
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "100px" }}>Y Axis</Label>
              <Input
                type="checkbox"
                checked={bubbleScatterChartOptions.yAxisAuto}
                onChange={e =>
                  changeBubbleScatterChartOption(
                    "yAxisAuto",
                    !bubbleScatterChartOptions.yAxisAuto
                  )
                }
              />
              <Label style={{ marginLeft: "10px" }}>Auto</Label>
              <Label style={{ marginLeft: "20px" }}>Min</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "120px", marginLeft: "10px" }}
                value={bubbleScatterChartOptions.minY}
                onChange={e =>
                  changeBubbleScatterChartOption("minY", e.target.value)
                }
              />
              <Label style={{ marginLeft: "10px" }}>Max</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "120px", marginLeft: "10px" }}
                value={bubbleScatterChartOptions.maxY}
                onChange={e =>
                  changeBubbleScatterChartOption("maxY", e.target.value)
                }
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "100px" }}>X Axis</Label>
              <Input
                type="checkbox"
                checked={bubbleScatterChartOptions.xAxisAuto}
                onClick={e =>
                  changeBubbleScatterChartOption(
                    "xAxisAuto",
                    !bubbleScatterChartOptions.xAxisAuto
                  )
                }
              />
              <Label style={{ marginLeft: "10px" }}>Auto</Label>
              <Label style={{ marginLeft: "20px" }}>Min</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "120px", marginLeft: "10px" }}
                value={bubbleScatterChartOptions.minX}
                onChange={e =>
                  changeBubbleScatterChartOption("minX", e.target.value)
                }
              />
              <Label style={{ marginLeft: "10px" }}>Max</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "120px", marginLeft: "10px" }}
                value={bubbleScatterChartOptions.maxX}
                onChange={e =>
                  changeBubbleScatterChartOption("maxX", e.target.value)
                }
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label className="form-label">X Axis field</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={bubbleScatterChartOptions.xAxisField}
                style={{ width: "200px", marginLeft: "30px" }}
                onChange={e =>
                  changeBubbleScatterChartOption("xAxisField", e.target.value)
                }
              >
                <option value="Cost">Total Cost</option>
                <option value="NPVCost">NPV Cost</option>
                <option value="ProjectPostProject">
                  Project and Post Project Cost
                </option>
                <option value="Return">Return ($)</option>
                <option value="NPVReturn">NPV Return ($)</option>
                <option value="ROI">ROI (%)</option>
                <option value="IRR">IRR (%)</option>
                <option value="Payback">PBP (Years)</option>
              </select>
              <Input
                style={{ marginLeft: "20px" }}
                type="checkbox"
                checked={bubbleScatterChartOptions.showNames}
                onClick={e =>
                  changeBubbleScatterChartOption(
                    "minX",
                    !bubbleScatterChartOptions.showNames
                  )
                }
              />
              <Label style={{ marginLeft: "10px" }}>Show Names</Label>
            </div>
          </Col>
          <Col md="3" sm="3">
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 1 Label</Label>
              <Input
                name="q1l"
                type="text"
                value={bubbleScatterChartOptions.q1}
                onChange={e =>
                  changeBubbleScatterChartOption("q1", e.target.value)
                }
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 3 Label</Label>
              <Input
                name="q3l"
                type="text"
                value={bubbleScatterChartOptions.q3}
                onChange={e =>
                  changeBubbleScatterChartOption("q3", e.target.value)
                }
              />
            </div>
            <button className="btn btn-primary" onClick={loadChart}>
              Refresh
            </button>
          </Col>
          <Col md="3" sm="3">
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 2 Label</Label>
              <Input
                name="q2l"
                type="text"
                value={bubbleScatterChartOptions.q2}
                onChange={e =>
                  changeBubbleScatterChartOption("q2", e.target.value)
                }
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 4 Label</Label>
              <Input
                name="q4l"
                type="text"
                value={bubbleScatterChartOptions.q4}
                onChange={e =>
                  changeBubbleScatterChartOption("q4", e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div id="chartdiv" style={{ width: "100%", height: "600px" }}></div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default AltScatterChartModal
